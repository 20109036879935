import React from "react";
import "../css/ministerios.css";
import { Link } from "react-router-dom";

function Ministerios(props) {
  return (
    <div id="fundo">
      <div style={{ height: "80px" }}></div>
      <section id="ministerios">
        <h1 className="titulo">MINISTÉRIOS</h1>
        <div style={{ height: "8px" }}></div>
        <h5 className="subtitulo">
          Conheça os ministérios da Igreja Cristã Nascer de Novo
        </h5>

        <div style={{ height: "46px" }}></div>

        <div id="ministeriosLayout">
          <div className="ministerioFundo">
            <div id="ministerioFoto1"></div>
            <span className="ministerioTitulo">Pontos de Encontro</span>
            <span className="ministerioDescricao">
              Queremos fazer vida juntos. E queremos que a vida ultrapasse as
              paredes da igreja física e que fique o mais próximo possível à tua
              realidade.
            </span>
            <span className="ministerioDescricao">
              Os Pontos de Encontro são pequenos grupos locais/familiares que se
              reúnem regularmente em casa dos membros da Nascer de Novo
            </span>
            <span className="ministerioDescricao">
              Aqui encontras algum perto de ti:
            </span>
            <span className="ministerioDescricao">
              - São João de Vêr (Quinta do Arieiro)
            </span>
            <span className="ministerioDescricaoItems">- Argoncilhe</span>
            <span className="ministerioDescricaoItems">- Espinho</span>
            <span className="ministerioDescricao">
              Caso vivas próximo, ou queiras participar, entra em contacto:
            </span>
            <span className="ministerioDescricaoItems">
              - i.nascerdenovo@gmail.com
            </span>
            <span className="ministerioDescricaoItems">- 933 444 263 </span>
            <div style={{ height: "20px" }} />
          </div>

          <div className="ministerioFundo">
            <div id="ministerioFoto2"></div>
            <span className="ministerioTitulo">Louvor e Adoração</span>
            <span className="ministerioDescricao">
              O Grupo de Louvor da Nascer de Novo tem a missão de liderar a
              igreja nos momentos de Louvor durante as nossas reuniões, mas
              também de estabelecer o precedente divino de devoção de vida no
              louvor a Deus.
            </span>
            <span className="ministerioDescricao">
              As nossas atividades incluem: ensaios, colaboração nas reuniões,
              aulas de instrumento.
            </span>
            <span className="ministerioDescricao">
              Os nossos princípios podem ser encontrados mais detalhadamente no
              seguinte documento:
            </span>
            <div style={{ height: "10px" }} />
            <span
              style={{ cursor: "pointer", fontWeight: "300" }}
              className="ministerioDescricaoItems"
              onClick={() =>
                window.open(
                  "  https://drive.google.com/file/d/1-xI5qO-GmXBs8PPwwb-tsTe3fkh0Q0go/view",
                  "_blank"
                )
              }
            >
              https://drive.google.com/file/d/1-xI5qO-GmXBs8PPwwb-tsTe3fkh0Q0go/view
            </span>

            <div style={{ height: "20px" }} />
          </div>

          <div className="ministerioFundo">
            <div id="ministerioFoto3"></div>
            <span className="ministerioTitulo">Intercessão</span>
            <span className="ministerioDescricao">
              Acreditamos que um dos fundamentos da Igreja é a oração. É
              importante orar individualmente, mas também coletivamente.
            </span>
            <span className="ministerioDescricao">
              Deus nos convoca a “Orar sem cessar.” 1 Tessalonicenses 5:17.
            </span>
            <span className="ministerioDescricaoItems">
              O ato de interceder pelas pessoas que precisam é uma demonstração
              de amor ao próximo, que é encorajado nas Escrituras (Efésios 6:18)
            </span>
            <span className="ministerioDescricao">
              A nossa equipa de intercessão está disponível para orar por ti.
            </span>
            <Link
              to="/contactos"
              className="ministerioDescricaoItems"
              style={{
                cursor: "pointer",
                textDecoration: "none",
                color: "inherit",
              }}
              onClick={() => props.navegacaoContactos(3)}
            >
              Clique aqui para efetuar um pedido de oraçao
            </Link>

            <div style={{ height: "20px" }} />
          </div>

          <div className="ministerioFundo">
            <div id="ministerioFoto4"></div>
            <span className="ministerioTitulo">Infantil</span>
            <span className="ministerioDescricao">
              A nossa igreja é para toda a família, isso também inclui as
              crianças e adolescentes.
            </span>
            <span className="ministerioDescricao">
              Temos um espaço reservado para serem acolhidas e cuidadas pela
              nossa equipa mais que preparada, para assim poderem receber a
              Palavra de Deus de forma simples e eficaz
            </span>
            <span className="ministerioDescricao">
              A nossa didática inclui: aulas dinâmicas, tecnologia, jogos
              manuais, pintura, desenho, dança, teatro, etc.
            </span>

            <div style={{ height: "20px" }} />
          </div>

          <div className="ministerioFundo">
            <div id="ministerioFoto5"></div>
            <span className="ministerioTitulo">Social</span>
            <span className="ministerioDescricao">
              Somos igreja dentro e fora do espaço de culto.
            </span>
            <span className="ministerioDescricao">
              A Nascer de Novo se envolve na vida e necessidade quotidiana da
              nossa comunidade onde houver necessidade.
            </span>
            <span className="ministerioDescricao">
              A nossa ajuda se traduz em: Coleta e doação de alimentos, roupas e
              bens para as famílias carenciadas, organização de eventos
              beneficentes, entre outros.
            </span>

            <div style={{ height: "20px" }} />
          </div>

          <div className="ministerioFundo">
            <div id="ministerioFoto6"></div>
            <span className="ministerioTitulo">Jovens</span>
            <span className="ministerioDescricao">
              Os nossos jovens são apaixonados por Deus.
            </span>
            <span className="ministerioDescricao">
              Promovemos os valores Cristãos de forma dinâmica, procurando fazer
              vida juntos. Isso inclui reuniões, convívios, entre outras
              atividades.
            </span>
            <span className="ministerioDescricao">
              Fica atento porque temos sempre novidades.
            </span>

            <div style={{ height: "20px" }} />
          </div>

          <div className="ministerioFundo">
            <div id="ministerioFoto7"></div>
            <span className="ministerioTitulo">Artes</span>
            <span className="ministerioDescricao">
              Adoração não inclui apenas música, mas também outras expressões
              artísticas que vão de encontro com o coração do Pai. Pretendemos
              incentivar e desenvolver essas áreas como: dança, teatro, artes
              plásticas.
            </span>
            <span className="ministerioDescricao">
              Tudo quanto tem fôlego louve ao Senhor. Louvai ao Senhor.
            </span>
            <span className="ministerioDescricaoItems">Salmos 150:6</span>

            <div style={{ height: "20px" }} />
          </div>

          <div className="ministerioFundo">
            <div id="ministerioFoto8"></div>
            <span className="ministerioTitulo">Missões</span>
            <span className="ministerioDescricao">
              Pretendemos contribuir para a expansão do Evangelho, enviando e
              apoiando iniciativas Missionárias à medida do possível.
            </span>
            <span className="ministerioDescricao">
              Aqui ficam as causas que apoiamos:
            </span>
            <div style={{ height: "10px" }} />
            <span
              className="ministerioDescricaoItems"
              style={{ cursor: "pointer", fontWeight: "300" }}
              onClick={() =>
                window.open("http://maosporcazombo.org/", "_blank")
              }
            >
              http://maosporcazombo.org/
            </span>
          </div>
        </div>
        <div style={{ height: "80px" }}></div>
      </section>
    </div>
  );
}

export default Ministerios;
