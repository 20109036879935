import React, { useState, useEffect, useContext } from "react";
import "../../css/visitas_marcadas.css";
import axios from "axios";
import { SessionContext } from "../../SesstionContext";
import Loader from "../../img/loader.svg";
import { formatarData } from "../../DateHelper";

function VisitasMarcadas() {
  const [visitasMarcadas, setVisitasMarcadas] = useState([]);
  const [session, setSession] = useContext(SessionContext);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/api/v1/visitas`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + session.token,
        },
        withCredentials: true,
      })
      .then(
        (response) => {
          setLoading(false);
          setVisitasMarcadas(response.data);
        },
        (error) => {
          setLoading(false);
          if (
            error.response !== undefined &&
            error.response.data.statusCode === "3"
          ) {
            setSession({
              nome: "",
              email: "",
              cargo: "",
              token: "",
              logado: false,
            });
            document.cookie =
              "token=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
          }
        }
      );
    // eslint-disable-next-line
  }, []);

  return !loading ? (
    <section id="visitasMarcadasLayout">
      <span className="funcionalidadeTitulo">VISITAS MARCADAS</span>
      <div id="fundoVisitasMarcadas">
        <table id="visitasMarcadas">
          <thead>
            <tr>
              <th>Nome</th>
              <th>Contacto</th>
              <th>Crianças</th>
              <th>Data marcação</th>
              <th>Informação Adicional</th>
            </tr>
          </thead>
          <tbody>
            {visitasMarcadas.map((visita, index) => {
              return (
                <tr key={index}>
                  <td>{visita.nome}</td>
                  <td>
                    {visita.entrarEmContacto
                      ? visita.contacto.length === 0
                        ? "Sem contacto"
                        : visita.contacto
                      : "Não quer ser contactado"}
                  </td>
                  <td>{visita.levaCriancas ? "Sim" : "Não"}</td>
                  <td>{formatarData(visita.data)}</td>
                  <td>
                    {visita.informacaoAdicional.length > 0
                      ? visita.informacaoAdicional
                      : "Sem informação adicional"}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </section>
  ) : (
    <img className="loader" src={Loader} alt="" />
  );
}

export default VisitasMarcadas;
