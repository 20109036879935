import React, { useState, useContext, useRef } from "react";
import { SessionContext } from "../SesstionContext";
import { Redirect, Link } from "react-router-dom";
import { getTokenCookie } from "../CookieHelper";
import axios from "axios";
import "../css/login.css";

export default function Login() {
  const emailRef = useRef();
  const [erroEmail, setErroEmail] = useState("");
  const passwordRef = useRef();
  const [erroPassword, setErroPassword] = useState("");
  const [session, setSession] = useContext(SessionContext);

  const fazerLogin = (e) => {
    e.preventDefault();

    setErroEmail("");
    setErroPassword("");

    const email = emailRef.current.value;
    const password = passwordRef.current.value;

    if (email.length === 0) {
      setErroEmail("Por favor insira o seu email");
      return;
    } else if (!email.includes("@") || !email.includes(".")) {
      setErroEmail("Email com formato inválido");
      return;
    } else if (password.length === 0) {
      setErroPassword("Por favor insira a sua palavra-passe");
      return;
    }

    const loginBotao = document.getElementById("loginBtn");
    loginBotao.classList.add("noHover");

    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/api/v1/login`,
        {
          username: email,
          password: password,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      )
      .then(
        (response) => {
          const { jwt, name, email, userRole } = response.data;
          setSession({
            nome: name,
            email: email,
            cargo: userRole,
            token: jwt,
            logado: true,
          });
          document.cookie =
            "token=" + jwt + ";expires=" + new Date(2100, 0, 1).toUTCString();
          loginBotao.classList.remove("noHover");
        },
        (error) => {
          if (
            error.response !== undefined &&
            error.response.data.statusCode === "1"
          ) {
            setErroEmail("Credenciais inválidas");
          } else {
            setErroEmail("Ocorreu um erro");
          }
          loginBotao.classList.remove("noHover");
        }
      );
  };

  const verificarToken = () => {
    const token = getTokenCookie("token");

    if (token.length > 0 && session.email === "") {
      return <Redirect to="/redirect" />;
    }
  };

  return (
    <div id="loginLayout">
      <div id="loginFoto"></div>
      <section id="login">
        <div id="loginFundo">
          <span id="loginTitulo">Bem vindo de volta</span>
          <div style={{ marginTop: "50px" }} />

          <form onSubmit={fazerLogin}>
            <input
              type="text"
              id="loginEmail"
              placeholder="Email"
              className="campoFormulario"
              name="email"
              ref={emailRef}
              maxLength="100"
            />
            <span
              className="loginErro"
              style={erroEmail ? { display: "block" } : { display: "none" }}
            >
              {erroEmail}
            </span>
            <input
              type="password"
              id="loginPassword"
              placeholder="Palavra-Passe"
              className="campoFormulario"
              name="password"
              ref={passwordRef}
              maxLength="100"
            />
            <span
              className="loginErro"
              style={erroPassword ? { display: "block" } : { display: "none" }}
            >
              {erroPassword}
            </span>
            <button id="loginBtn">ENTRAR</button>
          </form>

          <span id="loginCriarNovaConta">
            <Link
              to="/registo"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              Criar nova conta
            </Link>
          </span>

          {/*
            <div id="loginSeparador">
            <div className="loginSeparadorLinha"></div>
            <span id="linhaSeparadorOu">ou</span>
            <div className="loginSeparadorLinha"></div>
          </div>
            <div id="loginRedesSociaisLayout">
            <div id="loginRedeSocial3" onClick={this.fazerLoginGoogle}>
              <div id="loginRedeSocial3Logo"></div>
            </div>
          </div>
            */}
        </div>
      </section>
      {session.logado ? <Redirect to="/redirect" /> : null}
      {verificarToken()}
    </div>
  );
}
