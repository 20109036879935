import React, { useContext, useEffect, useState } from "react";
import { SessionContext } from "../SesstionContext";
import Loader from "../img/loader.svg";
import { Redirect } from "react-router-dom";
import { getTokenCookie } from "../CookieHelper";
import axios from "axios";

export default function Redirecionar() {
  const [session, setSession] = useContext(SessionContext);
  const [recebeuCredenciais, setRecebeuCredenciais] = useState(false);

  const redirecionar = () => {
    if (session.cargo === "MEMBRO") {
      return (
        <Redirect
          to={{
            pathname: "/membro",
            state: { cargo: "1" },
          }}
        />
      );
    } else if (session.cargo === "VOLUNTARIO") {
      return (
        <Redirect
          to={{
            pathname: "/voluntario",
            state: { cargo: "2" },
          }}
        />
      );
    } else if (session.cargo === "STAFF") {
      return (
        <Redirect
          to={{
            pathname: "/staff",
            state: { cargo: "3", nome: session.nome },
          }}
        />
      );
    } else {
      return <Redirect to="/login" />;
    }
  };

  const iniciarSessao = () => {
    const token = getTokenCookie("token");

    if (token.length > 0 && session.email === "") {
      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/api/v1/userDetails`,
          {
            token: token,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
            withCredentials: true,
          }
        )
        .then(
          (response) => {
            const { name, email, userRole } = response.data;
            setSession({
              nome: name,
              email: email,
              cargo: userRole,
              token: token,
              logado: true,
            });
            setRecebeuCredenciais(true);
          },
          (error) => {
            setSession({
              nome: "",
              email: "",
              cargo: "",
              token: "",
              logado: false,
            });
            document.cookie =
              "token=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
            setRecebeuCredenciais(true);
          }
        );
    } else {
      setRecebeuCredenciais(true);
    }
  };

  useEffect(() => {
    iniciarSessao();
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <img
        src={Loader}
        alt=""
        width="150px"
        style={{ position: "fixed", top: "46%", left: "46%" }}
      />
      {recebeuCredenciais ? redirecionar() : null}
    </div>
  );
}
