import React, { useState, useEffect, useContext } from "react";
import "../../css/pedidos_oracao.css";
import axios from "axios";
import { SessionContext } from "../../SesstionContext";
import Loader from "../../img/loader.svg";

function PedidosOracao() {
  const [pedidosOracao, setPedidosOracao] = useState([]);
  const [session, setSession] = useContext(SessionContext);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/api/v1/pedidosOracao`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + session.token,
        },
        withCredentials: true,
      })
      .then(
        (response) => {
          setPedidosOracao(response.data);
          setLoading(false);
        },
        (error) => {
          setLoading(false);
          if (
            error.response !== undefined &&
            error.response.data.statusCode === "3"
          ) {
            setSession({
              nome: "",
              email: "",
              cargo: "",
              token: "",
              logado: false,
            });
            document.cookie =
              "token=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
          }
        }
      );
    // eslint-disable-next-line
  }, []);

  return !loading ? (
    <section id="pedidosOracaoLayout">
      <span className="funcionalidadeTitulo">PEDIDOS DE ORAÇÃO</span>

      <div id="fundoPedidosOracao">
        <table id="pedidosOracao">
          <thead>
            <tr>
              <th>Nome</th>
              <th>Contacto</th>
              <th>Pedido</th>
            </tr>
          </thead>
          <tbody>
            {pedidosOracao.map((pedido, index) => {
              return (
                <tr key={index}>
                  <td>{pedido.nome}</td>
                  <td>
                    {pedido.entrarEmContacto
                      ? pedido.contacto.length === 0
                        ? "Sem contacto"
                        : pedido.contacto
                      : "Não quer ser contactado"}
                  </td>
                  <td>{pedido.pedido}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </section>
  ) : (
    <img className="loader" src={Loader} alt="" />
  );
}

export default PedidosOracao;
