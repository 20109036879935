import React, { useState, useContext } from "react";
import "../../css/publicar_devocional.css";
import "react-toastify/dist/ReactToastify.css";
import { gerarData, formatarData } from "../../DateHelper";
import { SessionContext } from "../../SesstionContext";
import axios from "axios";

function PublicarDevocional() {
  const [tipo, setTipo] = useState("IMAGEM_TEXTO");
  const [categoria, setCategoria] = useState("SERIE_MENSAGEM");
  const [imagem, setImagem] = useState("");
  const [video, setVideo] = useState("");
  const [titulo, setTitulo] = useState("");
  const [textoBase, setTextoBase] = useState("");
  const [mensagem, setMensagem] = useState("");
  const [erro, setErro] = useState("");
  const [sucesso, setSucesso] = useState("");

  const [session, setSession] = useContext(SessionContext);

  const tipoMensagem = () => {
    if (categoria === "SERIE_MENSAGEM") {
      return "SÉRIE DE MENSAGENS";
    } else if (categoria === "DEVOCIONAL") {
      return "DEVOCIONAL";
    } else if (categoria === "ESTUDO") {
      return "ESTUDO";
    }
  };

  const atualizarTipo = (e) => {
    setTipo(e.target.value);
  };

  const publicarDevocional = (e) => {
    e.preventDefault();

    setSucesso("");

    if (imagem.length === 0) {
      setErro("Por favor faça upload da imagem");
      return;
    } else if (tipo === "VIDEO_TEXTO" && video.length === 0) {
      setErro(
        "Por favor insira o src do video (video youtube, partilhar, incorporar, copiar src sem aspas)"
      );
      return;
    } else if (titulo.length === 0) {
      setErro("Por favor insira o título da mensagem");
      return;
    } else if (tipo === "IMAGEM_TEXTO" && mensagem.length === 0) {
      setErro("Por favor insira a mensagem");
      return;
    }

    const bodyFormData = new FormData();
    bodyFormData.append("pasta", "devocionais");
    bodyFormData.append("imagem", imagem);

    const publicarDevocionalBtn = document.getElementById(
      "publicarDevocionalBtn"
    );
    publicarDevocionalBtn.classList.add("noHover");

    axios
      .post(`${process.env.REACT_APP_BASE_URL}/api/v1/imagens`, bodyFormData, {
        headers: {
          "Content-Type": "multipart/mixed;boundary=gc0p4Jq0M2Yt08jU534c0p",
          Authorization: "Bearer " + session.token,
        },
        withCredentials: true,
      })
      .then(
        (imageResponse) => {
          axios
            .post(
              `${process.env.REACT_APP_BASE_URL}/api/v1/devocionais`,
              {
                imagem: imageResponse.data,
                video: video,
                titulo: titulo,
                textoBase: textoBase,
                mensagem: mensagem,
                categoria: categoria,
                tipo: tipo,
                autor: session.nome,
                data: gerarData(),
              },
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: "Bearer " + session.token,
                },
                withCredentials: true,
              }
            )
            .then(
              (response) => {
                setSucesso("Devocional inserido com sucesso!");
                setErro("");
                setTipo("IMAGEM_TEXTO");
                setCategoria("SERIE_MENSAGEM");
                setImagem("");
                setVideo("");
                setMensagem("");
                setTextoBase("");
                setTitulo("");
                publicarDevocionalBtn.classList.remove("noHover");
              },
              (error) => {
                if (
                  error.response !== undefined &&
                  error.response.data.statusCode === "3"
                ) {
                  setSession({
                    nome: "",
                    email: "",
                    cargo: "",
                    token: "",
                    logado: false,
                  });
                  document.cookie =
                    "token=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
                }
              }
            );
        },
        (error) => {
          if (
            error.response !== undefined &&
            error.response.data.statusCode === "3"
          ) {
            setSession({
              nome: "",
              email: "",
              cargo: "",
              token: "",
              logado: false,
            });
            document.cookie =
              "token=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
          }
        }
      );
  };

  return (
    <div>
      <section id="mensagemDevocional">
        <div id="mensagemDevocionalImagemVideo">
          {tipo === "IMAGEM_TEXTO" ? (
            <img
              src={
                imagem === ""
                  ? "https://www.travisheightselementary.com/wp-content/uploads/2018/09/placeholder.png"
                  : imagem
              }
              alt=""
              width="100%"
              height="100%"
            />
          ) : (
            <iframe
              title="video"
              width="100%"
              height="100%"
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen="allowfullScreen"
              mozallowfullscreen="mozallowfullscreen"
              msallowfullscreen="msallowfullscreen"
              oallowfullscreen="oallowfullscreen"
              webkitallowfullscreen="webkitallowfullscreen"
              src={
                video === ""
                  ? "https://www.youtube.com/embed/NpEaa2P7qZI"
                  : video
              }
            />
          )}
        </div>

        <div id="mensagemDevocionalLayout">
          <span id="mensagemDevocionalCategoria">{tipoMensagem()}</span>
          <span id="mensagemDevocionalTitulo">
            {titulo ? titulo : "Título da mensagem"}
          </span>

          {textoBase ? (
            <blockquote>
              <span>{textoBase}</span>
            </blockquote>
          ) : (
            ""
          )}

          <span
            id="mensagemDevocionalMensagem"
            style={{ whiteSpace: "pre-line" }}
          >
            {tipo === "IMAGEM_TEXTO"
              ? mensagem
                ? mensagem
                : "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum eleifend gravida leo, efficitur euismod lorem tempus eget. Curabitur lobortis elementum ipsum sed tincidunt. Morbi ac imperdiet magna. Mauris auctor ipsum nec finibus ullamcorper. Proin venenatis, mauris vitae laoreet blandit, dolor lectus commodo quam, at aliquet odio ligula id mi. Pellentesque quam felis, mollis non dolor nec, viverra laoreet felis. Aenean vestibulum sed metus a tempor."
              : mensagem
              ? mensagem
              : ""}
          </span>

          <span id="mensagemDevocionalData">
            Por {session.nome}&nbsp;{formatarData(gerarData())}
          </span>
        </div>
      </section>

      <section id="publicarDevocional">
        <form onSubmit={publicarDevocional}>
          <div id="publicarDevocionalLayout">
            <div id="publicarDevocionalLayoutEsquerda">
              <div>
                <div className="publicarDevocionalFormularioLinha">
                  <label className="containerRadio">
                    Imagem e texto
                    <input
                      type="radio"
                      value="IMAGEM_TEXTO"
                      checked={tipo === "IMAGEM_TEXTO" ? true : false}
                      onChange={atualizarTipo}
                    />
                    <span className="checkmarkRadio"></span>
                  </label>
                </div>
                <div className="publicarDevocionalFormularioLinha">
                  <label className="containerRadio">
                    Vídeo e texto (opcional)
                    <input
                      type="radio"
                      value="VIDEO_TEXTO"
                      checked={tipo === "VIDEO_TEXTO" ? true : false}
                      onChange={atualizarTipo}
                    />
                    <span className="checkmarkRadio"></span>
                  </label>
                </div>
              </div>
              <div style={{ height: "10px" }} />
              {tipo === "IMAGEM_TEXTO" ? (
                <div>
                  {/*
                  <input
                    type="text"
                    placeholder="Link da imagem"
                    className="campoFormulario"
                    value={imagemVideo}
                    onChange={(e) => setImagemVideo(e.target.value)}
                    style={{ border: "1.3px solid #d1cfcf" }}
                  />
              */}
                  <input
                    type="file"
                    className="campoFormulario"
                    placeholder="Insira o ficheiro"
                    style={{ border: "1.3px solid #d1cfcf" }}
                    onChange={(e) => setImagem(e.target.files[0])}
                  />
                </div>
              ) : (
                <div>
                  <input
                    type="file"
                    className="campoFormulario"
                    style={{ border: "1.3px solid #d1cfcf" }}
                    onChange={(e) => setImagem(e.target.files[0])}
                  />
                  <input
                    type="text"
                    placeholder="Link do vídeo (video youtube, partilhar, incorporar,
                      copiar src sem aspas)"
                    className="campoFormulario"
                    value={video}
                    onChange={(e) => setVideo(e.target.value)}
                    style={{ border: "1.3px solid #d1cfcf" }}
                  />
                </div>
              )}

              <div className="select">
                <select onChange={(e) => setCategoria(e.target.value)}>
                  <option value="SERIE_MENSAGEM">Série de mensagens</option>
                  <option value="DEVOCIONAL">Devocional</option>
                  <option value="ESTUDO">Estudo</option>
                </select>
              </div>
            </div>
            <div id="publicarDevocionalLayoutDireita">
              <input
                type="text"
                placeholder="Título da mensagem"
                className="campoFormulario"
                value={titulo}
                onChange={(e) => setTitulo(e.target.value)}
                style={{ border: "1.3px solid #d1cfcf" }}
              />

              <input
                type="text"
                placeholder="Texto base (opcional)"
                className="campoFormulario"
                value={textoBase}
                onChange={(e) => setTextoBase(e.target.value)}
                style={{ border: "1.3px solid #d1cfcf" }}
              />

              <textarea
                placeholder={
                  tipo === "IMAGEM_TEXTO"
                    ? "Texto da mensagem"
                    : "Texto da mensagem (opcional)"
                }
                className="campoFormulario"
                style={{
                  resize: "none",
                  border: "1.3px solid #d1cfcf",
                }}
                rows="4"
                value={mensagem}
                onChange={(e) => setMensagem(e.target.value)}
              />
            </div>
          </div>
          <button
            id="publicarDevocionalBtn"
            className="btnFormulario"
            style={{
              textAlign: "center",
              marginTop: "0px",
              marginBottom: "30px",
            }}
          >
            PUBLICAR DEVOCIONAL
          </button>
          <span id="publicarDevocionalErro"> {erro === "" ? null : erro}</span>
          <span id="publicarDevocionalSucesso">
            {sucesso === "" ? null : sucesso}
          </span>
          <div style={{ height: "30px" }} />
        </form>
      </section>
    </div>
  );
}

export default PublicarDevocional;
