import React from "react";
import { Redirect } from "react-router-dom";
import "../css/mensagem_devocional.css";
import { formatarData } from "../DateHelper";

function MensagemDevocional(props) {
  const categoriaMensagem = () => {
    if (props.location.state.devocional.categoria === "SERIE_MENSAGEM") {
      return "SÉRIE DE MENSAGENS";
    } else if (props.location.state.devocional.categoria === "DEVOCIONAL") {
      return "DEVOCIONAL";
    } else if (props.location.state.devocional.categoria === "ESTUDO") {
      return "ESTUDO";
    }
  };

  return (
    <div>
      {props.location.state ? (
        <section id="mensagemDevocional">
          <div id="mensagemDevocionalImagemVideo">
            {props.location.state.devocional.tipo === "IMAGEM_TEXTO" ? (
              <img
                id="mensagemDevocionalImagemVideoImagem"
                src={props.location.state.devocional.imagem}
                alt=""
                width="100%"
                height="100%"
              />
            ) : (
              <iframe
                title="video"
                width="100%"
                height="100%"
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen="allowfullscreen"
                mozallowfullscreen="mozallowfullscreen"
                msallowfullscreen="msallowfullscreen"
                oallowfullscreen="oallowfullscreen"
                webkitallowfullscreen="webkitallowfullscreen"
                src={props.location.state.devocional.video}
              />
            )}
          </div>

          <div id="mensagemDevocionalLayout">
            <span id="mensagemDevocionalCategoria">{categoriaMensagem()}</span>
            <span id="mensagemDevocionalTitulo">
              {props.location.state.devocional.titulo}
            </span>

            {props.location.state.devocional.textoBase ? (
              <blockquote>
                <span>{props.location.state.devocional.textoBase}</span>
              </blockquote>
            ) : (
              ""
            )}

            <span
              id="mensagemDevocionalMensagem"
              style={{ whiteSpace: "pre-line" }}
            >
              {props.location.state.devocional.mensagem.replace(
                new RegExp("_n", "g"),
                "\n"
              )}
            </span>

            <span id="mensagemDevocionalData">
              Por {props.location.state.devocional.autor}
              &nbsp;
              {formatarData(props.location.state.devocional.data)}
            </span>
          </div>
        </section>
      ) : (
        <Redirect to="/mensagens_devocionais" />
      )}
    </div>
  );
}

export default MensagemDevocional;
