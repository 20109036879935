import React, { useState, useEffect } from "react";
import "../css/mensagens_devocionais.css";
import MensagensDevocionaisItem from "../components/mensagens_devocionais_item";
import Loader from "../img/loader.svg";
import axios from "axios";

export default function MensagensDevocionais() {
  const [categoria, setCategoria] = useState("TODOS");
  const [devocionais, setDevocionais] = useState([]);
  const [fadeIn, setFadeIn] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/api/v1/devocionais`, {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      })
      .then(
        (response) => {
          setLoading(false);
          setDevocionais(response.data);
        },
        (error) => {
          setLoading(false);
        }
      );
    // eslint-disable-next-line
  }, []);

  const atualizarMensagensDevocionais = (categoria) => {
    setCategoria(categoria);

    setFadeIn("mensagensDevocionaisLayout-active");

    setTimeout(() => {
      setFadeIn("");
    }, 500);
  };

  return (
    <div id="fundo">
      <div style={{ height: "80px" }}></div>

      {!loading ? (
        <section id="mensagensDevocionais">
          <h1 className="titulo">MENSAGENS E DEVOCIONAIS</h1>
          <div style={{ height: "8px" }}></div>
          <h5 className="subtitulo">
            Acompanhe os ultimos devocionais e pregações da nossa igreja
          </h5>

          <div style={{ height: "46px" }}></div>

          <div id="mensagensDevocionaisFiltrosLayout">
            <button
              className={
                categoria === "TODOS"
                  ? "mensagensDevocionaisFiltrarBtn mensagensDevocionaisFiltrarBtn-active"
                  : "mensagensDevocionaisFiltrarBtn"
              }
              onClick={() => atualizarMensagensDevocionais("TODOS")}
            >
              TODOS
            </button>
            <button
              className={
                categoria === "SERIE_MENSAGEM"
                  ? "mensagensDevocionaisFiltrarBtn mensagensDevocionaisFiltrarBtn-active"
                  : "mensagensDevocionaisFiltrarBtn"
              }
              onClick={() => atualizarMensagensDevocionais("SERIE_MENSAGEM")}
            >
              SÉRIES DE MENSAGENS
            </button>
            <button
              className={
                categoria === "DEVOCIONAL"
                  ? "mensagensDevocionaisFiltrarBtn mensagensDevocionaisFiltrarBtn-active"
                  : "mensagensDevocionaisFiltrarBtn"
              }
              onClick={() => atualizarMensagensDevocionais("DEVOCIONAL")}
            >
              DEVOCIONAIS
            </button>
            <button
              className={
                categoria === "ESTUDO"
                  ? "mensagensDevocionaisFiltrarBtn mensagensDevocionaisFiltrarBtn-active"
                  : "mensagensDevocionaisFiltrarBtn"
              }
              onClick={() => atualizarMensagensDevocionais("ESTUDO")}
            >
              ESTUDOS
            </button>
          </div>

          <div
            id="mensagensDevocionaisLayout"
            className={fadeIn ? "mensagensDevocionaisLayout-active" : ""}
          >
            {devocionais
              .filter((devocional) =>
                categoria === "TODOS"
                  ? true
                  : devocional.categoria === categoria
              )
              .map((devocional, index) => {
                return (
                  <MensagensDevocionaisItem
                    key={index}
                    devocional={devocional}
                    visible={true}
                  />
                );
              })}
            {devocionais.length % 2 === 1 ? (
              <MensagensDevocionaisItem
                devocional={devocionais[0]}
                visible={false}
              />
            ) : null}
          </div>
        </section>
      ) : (
        <img className="loader" src={Loader} alt="" />
      )}
    </div>
  );
}
