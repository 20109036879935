import React from "react";

function PaginaInvalida() {
  return (
    <div
      style={{
        fontFamily: "'Raleway', sans-serif",
        height: "90vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        fontWeight: "500",
        textAlign: "center",
      }}
    >
      <span style={{ fontSize: "100px", marginBottom: "10px" }}>404</span>
      <span style={{ fontSize: "40px" }}> Página não encontrada</span>
    </div>
  );
}

export default PaginaInvalida;
