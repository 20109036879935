import React, { Component } from "react";
import "./css/navbar.css";
import logo from "./img/logo.png";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Home from "./pages/home";
import Ministerios from "./pages/ministerios";
import MensagensDevocionais from "./pages/mensagens_devocionais";
import Contactos from "./pages/contactos";
import PaginaInvalida from "./pages/pagina_invalida";
import Redirecionar from "./pages/redirecionar";
import Login from "./pages/login";
import Registo from "./pages/registo";
import Politica from "./pages/politica";
import Staff from "./pages/staff";
import Membro from "./pages/membro";
import Voluntario from "./pages/voluntario";
import MensagemDevocional from "./pages/mensagem_devocional";
import { SessionProvider } from "./SesstionContext";

export class App extends Component {
  state = {
    indice: -1,
    //apagar depois quando nao for preciso !!!!!!
    controlosVideo: true,
  };

  navegacao = (indice) => {
    this.setState({ indice: indice });

    if (window.innerWidth <= "1000") {
      this.abrirFecharNavbar(true);
    } else {
      this.abrirFecharNavbar(false);
    }
  };

  removerIndiceNavegacao = () => {
    this.setState({ indice: -1 });
  };

  componentDidMount() {
    if (window.location.pathname === "/") this.setState({ indice: 0 });
    else if (window.location.pathname === "/ministerios")
      this.setState({ indice: 1 });
    else if (
      window.location.pathname === "/mensagens_devocionais" ||
      window.location.pathname === "/mensagem_devocional"
    )
      this.setState({ indice: 2 });
    else if (window.location.pathname === "/contactos")
      this.setState({ indice: 3 });
    else if (
      window.location.pathname === "/login" ||
      window.location.pathname === "/registo"
    )
      this.setState({ indice: 4 });
  }

  abrirFecharNavbar = (ativar) => {
    if (ativar) {
      const body = document.querySelector("body");
      const nav = document.querySelector(".nav-links");
      const navLinks = document.querySelectorAll(".nav-links li");

      nav.classList.toggle("nav-active");

      if (nav.classList.contains("nav-active")) {
        body.style.overflowY = "hidden";
        this.setState({ controlosVideo: false });
      } else {
        body.style.overflowY = "auto";
        this.setState({ controlosVideo: true });
      }

      navLinks.forEach((link, index) => {
        if (link.style.animation) {
          link.style.animation = "";
        } else
          link.style.animation = `navLinkFade 0.5s ease forwards ${
            index / 7 + 0.3
          }s`;
      });
    }
  };

  render() {
    return (
      <Router>
        <nav>
          <Link to="/" onClick={() => this.setState({ indice: 0 })}>
            <img src={logo} alt="" height="74px" />
          </Link>
          <ul className="nav-links">
            <li>
              <Link
                to="/"
                onClick={() => this.navegacao(0)}
                style={
                  this.state.indice === 0
                    ? { color: "#ffb516" }
                    : { color: "black" }
                }
              >
                Início
              </Link>
            </li>
            <li>
              <Link
                to="/ministerios"
                onClick={() => this.navegacao(1)}
                style={
                  this.state.indice === 1
                    ? { color: "#ffb516" }
                    : { color: "black" }
                }
              >
                Ministérios
              </Link>
            </li>
            <li>
              <Link
                to="/mensagens_devocionais"
                onClick={() => this.navegacao(2)}
                style={
                  this.state.indice === 2
                    ? { color: "#ffb516" }
                    : { color: "black" }
                }
              >
                Mensagens e Devocionais
              </Link>
            </li>
            <li>
              <Link
                to="/contactos"
                onClick={() => this.navegacao(3)}
                style={
                  this.state.indice === 3
                    ? { color: "#ffb516" }
                    : { color: "black" }
                }
              >
                Contactos
              </Link>
            </li>
            <li>
              <Link
                to="/redirect"
                onClick={() => this.navegacao(4)}
                style={
                  this.state.indice === 4
                    ? { color: "#ffb516" }
                    : { color: "black" }
                }
              >
                Login
              </Link>
            </li>
          </ul>

          <button className="doarBtn">DOAR</button>

          <div id="burgerFundo1" onClick={() => this.abrirFecharNavbar(true)}>
            <div id="burgerFundo2">
              <div id="burger"></div>
            </div>
          </div>
        </nav>

        <SessionProvider>
          <Switch>
            <Route
              path={process.env.PUBLIC_URL + "/"}
              exact
              component={() => (
                <Home
                  controlosVideo={this.state.controlosVideo}
                  navegacaoContactos={this.navegacao}
                />
              )}
            />
            <Route
              path={process.env.PUBLIC_URL + "/ministerios"}
              component={() => (
                <Ministerios navegacaoContactos={this.navegacao} />
              )}
            />
            <Route
              path={process.env.PUBLIC_URL + "/mensagens_devocionais"}
              component={MensagensDevocionais}
            />
            <Route path="/mensagem_devocional" component={MensagemDevocional} />
            <Route
              path={process.env.PUBLIC_URL + "/contactos"}
              component={Contactos}
            />
            <Route
              path={process.env.PUBLIC_URL + "/redirect"}
              component={Redirecionar}
            />
            <Route path={process.env.PUBLIC_URL + "/login"} component={Login} />
            <Route
              path={process.env.PUBLIC_URL + "/registo"}
              component={Registo}
            />
            <Route
              path={process.env.PUBLIC_URL + "/membro"}
              component={Membro}
            />
            <Route
              path={process.env.PUBLIC_URL + "/voluntario"}
              component={Voluntario}
            />
            <Route path={process.env.PUBLIC_URL + "/staff"} component={Staff} />

            <Route
              path={process.env.PUBLIC_URL + "/politica"}
              component={Politica}
            />
            <Route
              path={process.env.PUBLIC_URL + "/*"}
              component={PaginaInvalida}
            />
          </Switch>
        </SessionProvider>
      </Router>
    );
  }
}

export default App;
