import React from "react";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function ColunaNecessidadeEquipa({
  colunaPlaneamento,
  voluntarios,
  adicionarAbrirFechar,
}) {
  const mostrarEsconderColuna = () => {
    let mostrar = false;
    colunaPlaneamento.necessidades.forEach((necessidade) => {
      if (necessidade.quantidade !== 0) {
        mostrar = true;
      }
    });

    if (mostrar === true) {
      return (
        <div className="equipaCultosColuna">
          <span className="equipaCultosColunaTitulo">
            {colunaPlaneamento.nome}
          </span>
          {colunaPlaneamento.necessidades
            .filter((necessidade) => {
              return necessidade.quantidade !== 0;
            })
            .map((necessidade, index) => {
              return (
                <div key={index}>
                  {necessidade.preenchidas.length !== 0 ? (
                    <div className="equipaCultosColunaNecessidadePreenchida">
                      <span className="equipaCultosColunaNecessidadeTituloPreenchida">
                        {necessidade.descricao}
                      </span>
                      {necessidade.preenchidas.map(
                        (preenchida, indexPreenchida) => {
                          return (
                            <div key={indexPreenchida}>
                              {voluntarios.map(
                                (voluntario, indexVoluntario) => {
                                  if (voluntario.email === preenchida) {
                                    return (
                                      <div key={indexVoluntario}>
                                        <div className="equipaCultosColunaNecessidadeQuantidadePreenchida">
                                          <div className="equipaCultosColunaNecessidadeQuantidadeMostrarPreenchida">
                                            <div className="equipaCultosColunaNecessidadeQuantidadeMostrarNumeroPreenchida">
                                              <FontAwesomeIcon icon={faUser} />
                                            </div>
                                            <div className="equipaCultosColunaNecessidadeQuantidadeMostrarTextoPreenchida">
                                              {voluntario.name}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  }
                                  return null;
                                }
                              )}
                            </div>
                          );
                        }
                      )}
                    </div>
                  ) : (
                    <div className="equipaCultosColunaNecessidade">
                      <span className="equipaCultosColunaNecessidadeTitulo">
                        {necessidade.descricao}
                      </span>
                      <div
                        className="equipaCultosColunaNecessidadeQuantidade"
                        onClick={() => adicionarAbrirFechar(necessidade)}
                      >
                        <div className="equipaCultosColunaNecessidadeQuantidadeMostrar">
                          <div className="equipaCultosColunaNecessidadeQuantidadeMostrarNumero">
                            {necessidade.quantidade}
                          </div>
                          <span className="equipaCultosColunaNecessidadeQuantidadeMostrarTexto">
                            Precisos
                          </span>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              );
            })}
        </div>
      );
    }
  };

  return <>{mostrarEsconderColuna()}</>;
}
