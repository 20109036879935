import React, { useState, useContext } from "react";
import "../css/sidebar.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SessionContext } from "../SesstionContext";
import {
  faMusic,
  faBroom,
  faPrayingHands,
  faPray,
  faBible,
  faCalendarCheck,
  faUsers,
  faSignOutAlt,
  faBookOpen,
  faClipboardList,
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import {
  MemoryRouter as Router,
  Link,
  Route,
  Redirect,
} from "react-router-dom";
import PedidosOracao from "./voluntario/pedidos_oracao";
import EscalaLimpeza from "./membro/escala_limpeza";
import GrupoLouvor from "./voluntario/grupo_louvor";
import PlaneamentoCultos from "./staff/planeamento_cultos";
import EscalaOracao from "./voluntario/escala_oracao";
import PublicarDevocional from "./staff/publicar_devocional";
import VisitasMarcadas from "./staff/visitas_marcadas";
import CalendarioAnual from "./membro/calendario_anual";
import EstatutosIcnn from "./membro/estatutos_icnn";

export default function Staff() {
  const [index, setIndex] = useState(0);
  const [session, setSession] = useContext(SessionContext);
  const [estendido, setEstendido] = useState(true);

  const atualizarIndeice = (indice) => {
    setIndex(indice);
  };

  const fazerLogout = () => {
    setSession({
      nome: "",
      email: "",
      cargo: "",
      token: "",
      logado: false,
    });
    document.cookie = "token=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
  };

  const abrirFechar = () => {
    const names = document.getElementsByClassName("sidebarLinhaNome");
    if (estendido) {
      document.getElementById("sidebarLayout").style.width = "65px";
      document.getElementById("sidebarTitulo").style.display = "none";
      for (let i = 0; i < names.length; i++) {
        names[i].style.display = "none";
      }
      setEstendido(false);
    } else {
      document.getElementById("sidebarLayout").style.width = "250px";
      document.getElementById("sidebarTitulo").style.display = "block";
      for (var i = 0; i < names.length; i++) {
        names[i].style.display = "block";
      }
      setEstendido(true);
    }
  };

  return session.logado ? (
    <div id="cargoLayout">
      <Router initialEntries={["/publicar_devocional"]}>
        <div id="sidebarLayout">
          <div id="sidebarLinks">
            <span id="sidebarCabecalho">
              <span id="sidebarTitulo">STAFF</span>
              <div id="sidebarFecharAbrir" onClick={abrirFechar}>
                <FontAwesomeIcon
                  icon={estendido ? faChevronLeft : faChevronRight}
                />
              </div>
            </span>

            <Link
              to="publicar_devocional"
              style={{ textDecoration: "none" }}
              onClick={() => atualizarIndeice(0)}
            >
              <div
                className={
                  index === 0
                    ? "sidebarLinha sidebarLinha-active"
                    : "sidebarLinha"
                }
              >
                <div className="sidebarLinhaIcon">
                  <FontAwesomeIcon icon={faBible} />
                </div>
                <span className="sidebarLinhaNome">Publicar devocional</span>
              </div>
            </Link>

            <Link
              to="planeamento_cultos"
              style={{ textDecoration: "none" }}
              onClick={() => atualizarIndeice(3)}
            >
              <div
                className={
                  index === 3
                    ? "sidebarLinha sidebarLinha-active"
                    : "sidebarLinha"
                }
              >
                <div className="sidebarLinhaIcon">
                  <FontAwesomeIcon icon={faClipboardList} />
                </div>
                <span className="sidebarLinhaNome">Planeamento cultos</span>
              </div>
            </Link>

            <Link
              to="visitas_marcadas"
              style={{ textDecoration: "none" }}
              onClick={() => atualizarIndeice(1)}
            >
              <div
                className={
                  index === 1
                    ? "sidebarLinha sidebarLinha-active"
                    : "sidebarLinha"
                }
              >
                <div className="sidebarLinhaIcon">
                  <FontAwesomeIcon icon={faUsers} />
                </div>
                <span className="sidebarLinhaNome">Visitas marcadas</span>
              </div>
            </Link>

            <Link
              to="/grupo_louvor"
              style={{ textDecoration: "none" }}
              onClick={() => atualizarIndeice(2)}
            >
              <div
                className={
                  index === 2
                    ? "sidebarLinha sidebarLinha-active"
                    : "sidebarLinha"
                }
              >
                <div className="sidebarLinhaIcon">
                  <FontAwesomeIcon icon={faMusic} />
                </div>
                <span className="sidebarLinhaNome">Grupo de Louvor</span>
              </div>
            </Link>

            <Link
              to="escala_oracao"
              style={{ textDecoration: "none" }}
              onClick={() => atualizarIndeice(4)}
            >
              <div
                className={
                  index === 4
                    ? "sidebarLinha sidebarLinha-active"
                    : "sidebarLinha"
                }
              >
                <div className="sidebarLinhaIcon">
                  <FontAwesomeIcon icon={faPray} />
                </div>
                <span className="sidebarLinhaNome">Escala de oração</span>
              </div>
            </Link>

            <Link
              to="pedidos_oracao"
              style={{ textDecoration: "none" }}
              onClick={() => atualizarIndeice(5)}
            >
              <div
                className={
                  index === 5
                    ? "sidebarLinha sidebarLinha-active"
                    : "sidebarLinha"
                }
              >
                <div className="sidebarLinhaIcon">
                  <FontAwesomeIcon icon={faPrayingHands} />
                </div>
                <span className="sidebarLinhaNome">Pedidios de oração</span>
              </div>
            </Link>

            <Link
              to="escala_limpeza"
              style={{ textDecoration: "none" }}
              onClick={() => atualizarIndeice(6)}
            >
              <div
                className={
                  index === 6
                    ? "sidebarLinha sidebarLinha-active"
                    : "sidebarLinha"
                }
              >
                <div className="sidebarLinhaIcon">
                  <FontAwesomeIcon icon={faBroom} />
                </div>
                <span className="sidebarLinhaNome">Escala de limpeza</span>
              </div>
            </Link>

            <Link
              to="calendario_anual"
              style={{ textDecoration: "none" }}
              onClick={() => atualizarIndeice(7)}
            >
              <div
                className={
                  index === 7
                    ? "sidebarLinha sidebarLinha-active"
                    : "sidebarLinha"
                }
              >
                <div className="sidebarLinhaIcon">
                  <FontAwesomeIcon icon={faCalendarCheck} />
                </div>
                <span className="sidebarLinhaNome">Calendário anual</span>
              </div>
            </Link>

            <Link
              to="estatutos_icnn"
              style={{ textDecoration: "none" }}
              onClick={() => atualizarIndeice(8)}
            >
              <div
                className={
                  index === 8
                    ? "sidebarLinha sidebarLinha-active"
                    : "sidebarLinha"
                }
              >
                <div className="sidebarLinhaIcon">
                  <FontAwesomeIcon icon={faBookOpen} />
                </div>
                <span className="sidebarLinhaNome">Estatutos ICNN</span>
              </div>
            </Link>
          </div>

          <div
            id="sidebarLogout"
            className="sidebarLinha"
            onClick={fazerLogout}
          >
            <div className="sidebarLinhaIcon">
              <FontAwesomeIcon icon={faSignOutAlt} />
            </div>
            <span
              className="sidebarLinhaNome"
              style={{ paddingBottom: "2.4px" }}
            >
              Logout
            </span>
          </div>
        </div>

        <div id="cargoConteudo">
          <Route path="/publicar_devocional" component={PublicarDevocional} />
          <Route path="/visitas_marcadas" component={VisitasMarcadas} />
          <Route path="/grupo_louvor" component={GrupoLouvor} />
          <Route path="/planeamento_cultos" component={PlaneamentoCultos} />
          <Route path="/escala_oracao" component={EscalaOracao} />
          <Route path="/pedidos_oracao" component={PedidosOracao} />
          <Route path="/escala_limpeza" component={EscalaLimpeza} />
          <Route path="/calendario_anual" component={CalendarioAnual} />
          <Route path="/estatutos_icnn" component={EstatutosIcnn} />
        </div>
      </Router>
    </div>
  ) : (
    <Redirect to="redirect" />
  );
}
