import React, { useState, useEffect, useContext } from "react";
import { SessionContext } from "../../SesstionContext";
import ColunaNecessidadePlaneamento from "../../components/planeamento_cultos/coluna_necessidade_planeamento";
import ColunaNecessidadeEquipa from "../../components/planeamento_cultos/coluna_necessidade_equipa";
import EstruturaCulto from "../../components/planeamento_cultos/estrutura_culto";
import "../../css/planeamento_cultos.css";
import { faTimes, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";

export default function PlaneamentoCultos() {
  const [tab, setTab] = useState(0);
  const [aberto, setAberto] = useState(false);
  const [planeamento, setPlaneamento] = useState({
    boasVindas: {
      nome: "Boas Vindas",
      necessidades: [
        {
          descricao: "Porteiro",
          quantidade: 0,
          preenchidas: [],
        },
        {
          descricao: "Receção",
          quantidade: 0,
          preenchidas: [],
        },
        {
          descricao: "Assistente de Sala",
          quantidade: 0,
          preenchidas: [],
        },
        {
          descricao: "Estacionamento",
          quantidade: 0,
          preenchidas: [],
        },
        {
          descricao: "Bar/Café",
          quantidade: 0,
          preenchidas: [],
        },
      ],
    },
    criancas: {
      nome: "Crianças",
      necessidades: [
        {
          descricao: "Cuidadora de Bebés",
          quantidade: 0,
          preenchidas: [],
        },
        {
          descricao: "Professor",
          quantidade: 0,
          preenchidas: [],
        },
      ],
    },
    multimedia: {
      nome: "Multimédia",
      necessidades: [
        {
          descricao: "Projeção e computador",
          quantidade: 0,
          preenchidas: [],
        },
        {
          descricao: "Luzes",
          quantidade: 0,
          preenchidas: [],
        },
        {
          descricao: "Som",
          quantidade: 0,
          preenchidas: [],
        },
        {
          descricao: "Redes Sociais",
          quantidade: 0,
          preenchidas: [],
        },
        {
          descricao: "Fotografia",
          quantidade: 0,
          preenchidas: [],
        },
        {
          descricao: "Operador de Câmera",
          quantidade: 0,
          preenchidas: [],
        },
        {
          descricao: "Apoio de Palco",
          quantidade: 0,
          preenchidas: [],
        },
      ],
    },
    louvor: {
      nome: "Louvor",
      necessidades: [
        {
          descricao: "Bateria",
          quantidade: 0,
          preenchidas: [],
        },
        {
          descricao: "Baixo",
          quantidade: 0,
          preenchidas: [],
        },
        {
          descricao: "Guitarra Elétrica",
          quantidade: 0,
          preenchidas: [],
        },
        {
          descricao: "Guitarra Acústica",
          quantidade: 0,
          preenchidas: [],
        },
        {
          descricao: "Piano/Teclado",
          quantidade: 0,
          preenchidas: [],
        },
        {
          descricao: "Voz",
          quantidade: 0,
          preenchidas: [],
        },
      ],
    },
    artes: {
      nome: "Artes",
      necessidades: [
        {
          descricao: "Dançarino",
          quantidade: 0,
          preenchidas: [],
        },
        {
          descricao: "Ator / Teatro",
          quantidade: 0,
          preenchidas: [],
        },
      ],
    },
    oradores: {
      nome: "Oradores",
      necessidades: [
        {
          descricao: "Abertura de Culto",
          quantidade: 0,
          preenchidas: [],
        },
        {
          descricao: "Avisos",
          quantidade: 0,
          preenchidas: [],
        },
        {
          descricao: "Ministração de Ofertas",
          quantidade: 0,
          preenchidas: [],
        },
        {
          descricao: "Momento de Oração",
          quantidade: 0,
          preenchidas: [],
        },
        {
          descricao: "Testemunho",
          quantidade: 0,
          preenchidas: [],
        },
        {
          descricao: "Sermão",
          quantidade: 0,
          preenchidas: [],
        },
      ],
    },
  });
  const [estrutura, setEstrutura] = useState([
    [
      {
        id: "1",
        content: "Abertura",
      },
      {
        id: "2",
        content: "Testemunho",
      },
      {
        id: "3",
        content: "Louvor",
      },
      {
        id: "4",
        content: "Sermão",
      },
      {
        id: "5",
        content: "Ofertas",
      },
      {
        id: "6",
        content: "Avisos",
      },
      {
        id: "7",
        content: "Apresentação Especial",
      },
      {
        id: "8",
        content: "Oração",
      },
      {
        id: "9",
        content: "Leitura",
      },
      {
        id: "10",
        content: "Batismo",
      },
      {
        id: "11",
        content: "Cerimónia",
      },
      {
        id: "12",
        content: "Certificação",
      },
    ],
    [],
  ]);

  const [musicas, setMusicas] = useState([]);

  const [voluntarios, setVoluntarios] = useState([]);
  const [necessidadeAtual, setNecessidadeAtual] = useState({});
  const [pesquisa, setPesquisa] = useState("");
  const [session, setSession] = useContext(SessionContext);

  const [dataInicioCulto, setDataInicioCulto] = useState("");
  const [dataFimCulto, setDataFimCulto] = useState("");
  const [mensagemErro, setMensagemErro] = useState("");
  const [mensagemSucesso, setMensagemSucesso] = useState("");

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/api/v1/users?userRole=VOLUNTARIO&userRole=STAFF&sorted=true`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + session.token,
          },
          withCredentials: true,
        }
      )
      .then(
        (response) => {
          response.data.forEach((user, index, users) => {
            users[index].temporaryPosition = "";
          });
          setVoluntarios(response.data);
        },
        (error) => {
          if (
            error.response !== undefined &&
            error.response.data.statusCode === "3"
          ) {
            setSession({
              nome: "",
              email: "",
              cargo: "",
              token: "",
              logado: false,
            });
            document.cookie =
              "token=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
          }
        }
      );
    // eslint-disable-next-line
  }, []);

  const incrementarDecrementarColunaPlaneamento = (
    operacao,
    coluna,
    indiceNecessidade
  ) => {
    let planeamentoAtual = planeamento;

    if (operacao === "+") {
      planeamentoAtual[coluna].necessidades[indiceNecessidade].quantidade++;
    } else {
      if (
        planeamentoAtual[coluna].necessidades[indiceNecessidade].quantidade >= 1
      )
        planeamentoAtual[coluna].necessidades[indiceNecessidade].quantidade--;
    }
    planeamentoAtual[coluna].necessidades[indiceNecessidade].preenchidas = [];
    setPlaneamento({ ...planeamentoAtual });
  };

  const preencherTemporaryPosition = (e, email) => {
    if (
      necessidadeAtual.preenchidas.length !== necessidadeAtual.quantidade ||
      e.target.checked === false
    ) {
      const voluntariosAtuais = voluntarios;
      const necessidadeAtualVar = necessidadeAtual;

      voluntariosAtuais.forEach((voluntario, index, voluntarios) => {
        if (voluntario.email === email) {
          if (e.target.checked === true) {
            voluntario.temporaryPosition = necessidadeAtual.descricao;
            necessidadeAtualVar.preenchidas = [
              ...necessidadeAtualVar.preenchidas,
              voluntario.email,
            ];
          } else {
            voluntario.temporaryPosition = "";
            necessidadeAtualVar.preenchidas =
              necessidadeAtualVar.preenchidas.filter(
                (preenchida) => preenchida !== voluntario.email
              );
          }
        }
      });
      setVoluntarios([...voluntariosAtuais]);
      setNecessidadeAtual({ ...necessidadeAtualVar });
    }
  };

  const adicionarAbrirFechar = (novaNecessidadeAtual) => {
    if (aberto === false) {
      setNecessidadeAtual({ ...novaNecessidadeAtual });
      document.getElementById(
        "planeamentoCultosEquipaAdicionarLayout"
      ).style.width = "450px";
      document.getElementById(
        "planeamentoCultosEquipaAdicionarTextoPessoasNecessarias"
      ).style.display = "block";
      document.getElementById(
        "planeamentoCultosEquipaAdicionarPesquisa"
      ).style.display = "block";
      setAberto(true);
    } else {
      setNecessidadeAtual({});
      document.getElementById(
        "planeamentoCultosEquipaAdicionarLayout"
      ).style.width = "0px";
      document.getElementById(
        "planeamentoCultosEquipaAdicionarTextoPessoasNecessarias"
      ).style.display = "none";
      document.getElementById(
        "planeamentoCultosEquipaAdicionarPesquisa"
      ).style.display = "none";
      setAberto(false);
      setPesquisa("");
    }
    const voluntariosAtuais = voluntarios;
    voluntariosAtuais.forEach((voluntario, index, voluntarios) => {
      voluntario.temporaryPosition = "";
    });
    setVoluntarios([...voluntariosAtuais]);
  };

  const atualizarTab = (tab) => {
    if (aberto === true) {
      document.getElementById(
        "planeamentoCultosEquipaAdicionarLayout"
      ).style.width = "0px";
      document.getElementById(
        "planeamentoCultosEquipaAdicionarPesquisa"
      ).style.display = "none";
      document.getElementById(
        "planeamentoCultosEquipaAdicionarTextoPessoasNecessarias"
      ).style.display = "none";
      setPesquisa("");
      setAberto(false);
    }
    setTab(tab);
    const voluntariosAtuais = voluntarios;
    voluntariosAtuais.forEach((voluntario, index, voluntarios) => {
      voluntario.temporaryPosition = "";
    });
    setVoluntarios([...voluntariosAtuais]);
    setNecessidadeAtual({});
  };

  const gerarTextoPessoasNecessarias = () => {
    if (Object.keys(necessidadeAtual).length !== 0) {
      if (
        necessidadeAtual.quantidade - necessidadeAtual.preenchidas.length ===
        0
      ) {
        return "Todas as posições preenchidas";
      } else if (
        necessidadeAtual.quantidade - necessidadeAtual.preenchidas.length ===
        1
      ) {
        return "1 pessoa em falta";
      } else {
        return (
          necessidadeAtual.quantidade -
          necessidadeAtual.preenchidas.length +
          " pessoas em falta"
        );
      }
    }
  };

  const adicionarMembrosPlaneamento = () => {
    if (
      Object.keys(necessidadeAtual).length !== 0 &&
      necessidadeAtual.quantidade - necessidadeAtual.preenchidas.length === 0
    ) {
      var planeamentoAtual = planeamento;
      Object.keys(planeamentoAtual).forEach((key, index) => {
        planeamentoAtual[key].necessidades.forEach(
          (necessidade, index, necessidades) => {
            if (necessidade.descricao === necessidadeAtual.descricao) {
              necessidade.preenchidas = necessidadeAtual.preenchidas;
            }
          }
        );
      });
      setPlaneamento({ ...planeamentoAtual });
      adicionarAbrirFechar();
    }
  };

  const mostrarEsconderTabEquipa = () => {
    let mostrar = false;

    Object.keys(planeamento).forEach((key) => {
      planeamento[key].necessidades.forEach((necessidade) => {
        if (necessidade.quantidade !== 0) {
          mostrar = true;
        }
      });
    });

    if (mostrar === true) {
      return (
        <div id="planeamentoCultosEquipaLayout">
          {Object.keys(planeamento).map((key, index) => {
            return (
              <ColunaNecessidadeEquipa
                key={index}
                colunaPlaneamento={planeamento[key]}
                voluntarios={voluntarios}
                adicionarAbrirFechar={adicionarAbrirFechar}
              />
            );
          })}
        </div>
      );
    } else {
      return (
        <span
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
            fontSize: "20px",
          }}
        >
          Não foram selecionadas funções
        </span>
      );
    }
  };

  const adicionarPlaneamento = () => {
    let postosAdicionados = false;
    let postosPreenchidos = true;

    Object.keys(planeamento).forEach((key) => {
      planeamento[key].necessidades.forEach((necessidade) => {
        if (necessidade.quantidade !== 0) {
          postosAdicionados = true;

          if (necessidade.quantidade !== necessidade.preenchidas.length) {
            postosPreenchidos = false;
          }
        }
      });
    });

    if (postosAdicionados === false) {
      setMensagemErro("Por favor adicione as funções necessárias");
    } else if (postosPreenchidos === false) {
      setMensagemErro(
        "Por favor verifique se todas as funções foram preenchidas"
      );
    } else {
      document
        .getElementById("planeamentoCultosEstruturaAdicionarBotao")
        .classList.add("noHover");
      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/api/v1/planning`,
          {
            dataInicio: dataInicioCulto,
            dataFim: dataFimCulto,
            estrutura: estrutura[1],
            planeamento: planeamento,
            musicas: musicas,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + session.token,
            },
            withCredentials: true,
          }
        )
        .then(
          (response) => {
            setMensagemSucesso("Planeamento adicionado com sucesso!");
            document
              .getElementById("planeamentoCultosEstruturaAdicionarBotao")
              .classList.remove("noHover");
          },
          (error) => {
            if (
              error.response !== undefined &&
              error.response.data.statusCode === "3"
            ) {
              setSession({
                nome: "",
                email: "",
                cargo: "",
                token: "",
                logado: false,
              });
              document.cookie =
                "token=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
            } else {
              setMensagemErro("Ocorreu um erro");
              document
                .getElementById("planeamentoCultosEstruturaAdicionarBotao")
                .classList.remove("noHover");
            }
          }
        );
    }
  };

  return (
    <div id="planeamentoCultosLayout">
      <div id="planeamentoCultosTabLayout">
        <span
          onClick={() => atualizarTab(0)}
          className="planeamentoCultosTab"
          style={tab === 0 ? { fontWeight: "700" } : null}
        >
          Planeamento
        </span>
        <span
          onClick={() => atualizarTab(1)}
          className="planeamentoCultosTab"
          style={tab === 1 ? { fontWeight: "700" } : null}
        >
          Equipa
        </span>
        <span
          onClick={() => atualizarTab(2)}
          className="planeamentoCultosTab"
          style={tab === 2 ? { fontWeight: "700" } : null}
        >
          Estrutura
        </span>
      </div>

      {tab === 0 && (
        <div id="planeamentoCultosPlaneamentoLayout">
          {Object.keys(planeamento).map((key, index) => {
            return (
              <ColunaNecessidadePlaneamento
                key={index}
                colunaPlaneamento={planeamento[key]}
                colunaPlaneamentoKey={key}
                incrementarDecrementarColunaPlaneamento={
                  incrementarDecrementarColunaPlaneamento
                }
              />
            );
          })}
        </div>
      )}

      {tab === 1 && mostrarEsconderTabEquipa()}

      {tab === 2 && (
        <EstruturaCulto
          estrutura={estrutura}
          setEstrutura={setEstrutura}
          dataInicioCulto={dataInicioCulto}
          setDataInicioCulto={setDataInicioCulto}
          dataFimCulto={dataFimCulto}
          setDataFimCulto={setDataFimCulto}
          musicas={musicas}
          setMusicas={setMusicas}
          mensagemErro={mensagemErro}
          setMensagemErro={setMensagemErro}
          mensagemSucesso={mensagemSucesso}
          setMensagemSucesso={setMensagemSucesso}
          adicionarPlaneamento={adicionarPlaneamento}
        />
      )}

      <div id="planeamentoCultosEquipaAdicionarLayout">
        <div id="planeamentoCultosEquipaAdicionarTopo">
          <div id="planeamentoCultosEquipaAdicionarTopoFechar">
            <span
              id="planeamentoCultosEquipaAdicionarTopoFecharBotao"
              onClick={adicionarAbrirFechar}
            >
              <FontAwesomeIcon icon={faTimes} />
            </span>

            <span id="planeamentoCultosEquipaAdicionarTopoFecharAtual">
              {necessidadeAtual.descricao}
            </span>
          </div>
          <div
            id="planeamentoCultosEquipaAdicionarTopoAdicionar"
            style={
              Object.keys(necessidadeAtual).length !== 0 &&
              necessidadeAtual.quantidade -
                necessidadeAtual.preenchidas.length ===
                0
                ? { opacity: "1", cursor: "pointer" }
                : null
            }
            onClick={adicionarMembrosPlaneamento}
          >
            <FontAwesomeIcon icon={faPlusCircle} />
            <span style={{ marginLeft: "5px" }}>Adicionar</span>
          </div>
        </div>
        <span id="planeamentoCultosEquipaAdicionarTextoPessoasNecessarias">
          {gerarTextoPessoasNecessarias()}
        </span>
        <input
          type="text"
          id="planeamentoCultosEquipaAdicionarPesquisa"
          placeholder="Procura"
          name="pesquisa"
          value={pesquisa}
          onChange={(e) => setPesquisa(e.target.value)}
        />
        <div style={{ marginTop: "20px" }}>
          {voluntarios
            ? voluntarios
                .filter((voluntario) => {
                  return pesquisa.length === 0
                    ? true
                    : voluntario.name
                        .toLowerCase()
                        .includes(pesquisa.toLowerCase());
                })
                .map((voluntario, index) => {
                  return (
                    <div
                      key={index}
                      className="planeamentoCultosEquipaAdicionarNome"
                    >
                      <label className="container">
                        {voluntario.name}
                        <input
                          type="checkbox"
                          onChange={(e) =>
                            preencherTemporaryPosition(e, voluntario.email)
                          }
                          checked={voluntario.temporaryPosition}
                        />
                        <span className="checkmark"></span>
                      </label>
                    </div>
                  );
                })
            : null}
        </div>
      </div>
    </div>
  );
}
