import React, { useRef } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const move = (source, destination, droppableSource, droppableDestination) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
};

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: "none",
  padding: "10px",
  borderTop: "2px solid #ebe9eb",
  background: isDragging ? "#eae6fe" : "#fff",
  width: "100%",
  textAlign: "left",

  // styles we need to apply on draggables
  ...draggableStyle,
});
const getListStyle = (isDraggingOver) => ({
  display: "flex",
  flexDirection: "column",
  background: isDraggingOver ? "#ffece8" : "#e9ecf0",
  minWidth: "250px",
  marginRight: "20px",
  border: "2px solid #ebe9eb",
});

export default function EstruturaCulto({
  estrutura,
  setEstrutura,
  dataInicioCulto,
  setDataInicioCulto,
  dataFimCulto,
  setDataFimCulto,
  musicas,
  setMusicas,
  mensagemErro,
  setMensagemErro,
  mensagemSucesso,
  setMensagemSucesso,
  adicionarPlaneamento,
}) {
  function onDragEnd(result) {
    const { source, destination } = result;

    // dropped outside the list
    if (!destination) {
      return;
    }
    const sInd = +source.droppableId;
    const dInd = +destination.droppableId;

    if (sInd === dInd) {
      const items = reorder(estrutura[sInd], source.index, destination.index);
      const novaEstrutura = [...estrutura];
      novaEstrutura[sInd] = items;
      setEstrutura(novaEstrutura);
    } else {
      const result = move(
        estrutura[sInd],
        estrutura[dInd],
        source,
        destination
      );
      const novaEstrutura = [...estrutura];
      novaEstrutura[sInd] = result[sInd];
      novaEstrutura[dInd] = result[dInd];
      setEstrutura(novaEstrutura);
      //setState(newState.filter((group) => group.length));
    }
  }

  const musicaRef = useRef();
  const tomRef = useRef();
  const liderRef = useRef();

  const adicionarMusica = () => {
    const musica = musicaRef.current.value;
    const tom = tomRef.current.value;
    const lider = liderRef.current.value;

    if (musica !== "" && tom !== "" && lider !== "") {
      setMusicas([...musicas, [musica, tom, lider]]);

      musicaRef.current.value = "";
      tomRef.current.value = "";
      liderRef.current.value = "";
    }
  };

  const apagarMusica = (i) => {
    const novasMusicas = musicas.filter((musicaLista, indice) => {
      return indice !== i;
    });
    setMusicas(novasMusicas);
  };

  const validarPlaneamento = () => {
    setMensagemErro("");
    setMensagemSucesso("");
    document
      .getElementById("planeamentoCultosEstruturaAdicionarBotao")
      .classList.remove("noHover");

    let musicasAdicionadasCasoLouvor = false;
    estrutura[1].forEach((estruturaItem) => {
      if (estruturaItem.id === "3" && musicas.length === 0) {
        musicasAdicionadasCasoLouvor = true;
      }
    });

    if (dataInicioCulto === "" || dataFimCulto === "") {
      setMensagemErro("Por favor preencha a data");
    } else if (estrutura[1].length === 0) {
      setMensagemErro("Por favor defina a estrutura do culto");
    } else if (musicasAdicionadasCasoLouvor === true) {
      setMensagemErro("Por favor preencha as músicas do louvor");
    } else {
      adicionarPlaneamento();
    }
  };

  return (
    <div id="planeamentoCultosEstruturaLayout">
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable key={0} droppableId={"0"}>
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
              {...provided.droppableProps}
            >
              <span className="planeamentoCultosEstruturaPlanoTitulo">
                Elementos
              </span>
              {estrutura[0].map((item, index) => (
                <Draggable key={item.id} draggableId={item.id} index={index}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style
                      )}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-around",
                        }}
                      >
                        {item.content}
                      </div>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>

        <Droppable key={1} droppableId={"1"}>
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
              {...provided.droppableProps}
            >
              <span className="planeamentoCultosEstruturaPlanoTitulo">
                Estrutura Culto
              </span>
              {estrutura[1].map((item, index) => (
                <Draggable key={item.id} draggableId={item.id} index={index}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style
                      )}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-around",
                        }}
                      >
                        {item.content}
                      </div>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <div id="planeamentoCultosEstruturaAdicionarLayout">
        {estrutura[1].map((estruturaItem, index) => {
          if (estruturaItem.id === "3") {
            return (
              <div key={index}>
                <span style={{ fontSize: "18px" }}>Músicas Louvor</span>
                {musicas.length !== 0 ? (
                  <table id="planeamentoCultosEstruturaMusicasTabela">
                    <thead>
                      <tr>
                        <th>Música</th>
                        <th>Tom</th>
                        <th>Líder</th>
                      </tr>
                    </thead>
                    <tbody>
                      {musicas.map((musica, i) => {
                        return (
                          <tr key={i}>
                            <td>{musica[0]}</td>
                            <td>{musica[1]}</td>
                            <td>
                              {musica[2]}

                              <FontAwesomeIcon
                                style={{
                                  marginLeft: "10px",
                                  cursor: "pointer",
                                }}
                                onClick={() => apagarMusica(i)}
                                icon={faTrash}
                              />
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                ) : null}
                <div id="planeamentoCultosEstruturaAdicionarMusicaLayout">
                  <input
                    className="planeamentoCultosEstruturaAdicionarMusicaInput"
                    type="text"
                    placeholder="Música"
                    ref={musicaRef}
                  />
                  <input
                    className="planeamentoCultosEstruturaAdicionarMusicaInput"
                    type="text"
                    placeholder="Tom"
                    ref={tomRef}
                  />
                  <input
                    className="planeamentoCultosEstruturaAdicionarMusicaInput"
                    type="text"
                    placeholder="Líder"
                    style={{ marginRight: "0px" }}
                    ref={liderRef}
                  />
                </div>
                <div
                  id="planeamentoCultosEstruturaAdicionarMusicaBotao"
                  onClick={adicionarMusica}
                >
                  <span>Adicionar música</span>
                </div>
              </div>
            );
          } else {
            return null;
          }
        })}

        <span style={{ fontSize: "18px", marginBottom: "10px" }}>
          Data de início
        </span>
        <input
          id="planeamentoCultosEstruturaAdicionarData"
          type="datetime-local"
          onChange={(e) => setDataInicioCulto(e.target.value)}
          value={dataInicioCulto}
        />
        <span style={{ fontSize: "18px", marginBottom: "10px" }}>
          Data de fim
        </span>
        <input
          id="planeamentoCultosEstruturaAdicionarData"
          type="datetime-local"
          onChange={(e) => setDataFimCulto(e.target.value)}
          value={dataFimCulto}
        />

        <div
          id="planeamentoCultosEstruturaAdicionarBotao"
          onClick={validarPlaneamento}
        >
          <span>Adicionar planeamento</span>
        </div>

        <span id="planeamentoCultosEstruturaAdicionarErro">{mensagemErro}</span>
        <span id="planeamentoCultosEstruturaAdicionarSucesso">
          {mensagemSucesso}
        </span>
      </div>
    </div>
  );
}
