import React from "react";

function EscalaLimpeza() {
  /*
   const [session, setSession] = useContext(SessionContext);

   
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/api/v1/cleaning`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + session.token,
        },
        withCredentials: true,
      })
      .then(
        (response) => {
          console.log("sucesso");
        },
        (error) => {
          console.log("erro");
          setSession({
            nome: "",
            email: "",
            cargo: "",
            token: "",
            logado: false,
          });
          document.cookie =
            "token=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
        }
      );
  }, []);
 */

  return <div>ESCALA DE LIMPEZA</div>;
}

export default EscalaLimpeza;
