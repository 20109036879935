import "../css/home.css";
import logoHome from "../img/logoHome.png";
import { Link } from "react-router-dom";
import React, { Component } from "react";
import Video from "../img/video.mp4";

export class Home extends Component {
  state = {
    mostrarModal: false,
    idModal: "",
    tituloModal: "",
    mensagemModal: [],
    referenciasModal: "",
  };

  redeSocial = (link) => {
    window.open(link, "_blank");
  };

  render() {
    return (
      <React.Fragment>
        <section>
          <div id="carousel">
            <video
              style={
                this.props.controlosVideo
                  ? { display: "block" }
                  : { display: "none" }
              }
              id="videoHome"
              width="320"
              height="240"
              controls={true}
            >
              <source src={Video} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </section>
        <section id="missao">
          <div id="logoMissao">
            <img src={logoHome} alt="" width="260px" />
          </div>
          <div id="infoMissao">
            <div style={{ height: "14px" }}></div>
            <div id="infoMissaoTitulo">Missão</div>
            <div id="infoMissaoDescricao">
              Temos por missão levar a Verdade de Deus ao mundo através do amor,
              anunciando o Evangelho de Jesus Cristo, discipulando e equipando
              as pessoas para que possam cumprir o seu propósito no mundo
              através da direção do Espírito Santo.
            </div>
            <hr id="infoMissaoSeparador" />
            <div id="infoMissaoVersiculo">Necessário vos é nascer de novo.</div>
            <div id="infoMissaoReferencia">João 3:7</div>
          </div>
        </section>
        <div style={{ height: "80px" }}></div>
        <section id="visita">
          <h1 className="titulo">PLANEAR VISITA</h1>

          <div style={{ height: "8px" }}></div>
          <h5 className="subtitulo">
            Tem interesse em visitar-nos e quer avisar para que estejamos
          </h5>
          <h5 className="subtitulo">mais bem preparados para recebê-lo?</h5>
          <div style={{ height: "46px" }}></div>
          <div className="visitaLayout">
            <div className="visitaHorario">
              <span className="visitaHorarioData">Domingo 10:00</span>
              <div className="visitaHorarioSeparador">
                <div className="visitaHorarioSeparadorLinha"></div>
                <div className="visitaHorarioSeparadorCirculoFundo">
                  <div className="visitaHorarioSeparadorCirculo"></div>
                </div>
                <div className="visitaHorarioSeparadorLinha"></div>
              </div>
            </div>
            <div className="visitaInfo">
              <div className="visitaInfoLayout">
                <div className="visitaInfoDados">
                  <span className="visitaInfoTitulo">Culto da família</span>
                  <span className="visitaInfoDescricao">
                    A nossa principal reunião para toda a família. Temos pessoas
                    preparadas para cuidar de si e dos seus filhos.
                  </span>
                  <Link
                    onClick={() => this.props.navegacaoContactos(3)}
                    to={{
                      pathname: `/contactos`,
                      state: { scroll: "visita" },
                    }}
                  >
                    <button className="visitarBtn">MARCAR VISITA</button>
                  </Link>
                </div>
                <div id="visitaInfoImagem1"></div>
              </div>
            </div>
          </div>

          <div className="visitaLayout">
            <div className="visitaHorario">
              <span className="visitaHorarioData">Quarta 20:00</span>
              <div className="visitaHorarioSeparador">
                <div className="visitaHorarioSeparadorLinha"></div>
                <div className="visitaHorarioSeparadorCirculoFundo">
                  <div className="visitaHorarioSeparadorCirculo"></div>
                </div>
                <div className="visitaHorarioSeparadorLinha"></div>
              </div>
            </div>
            <div className="visitaInfo">
              <div className="visitaInfoLayout">
                <div className="visitaInfoDados">
                  <span className="visitaInfoTitulo">Culto de oração</span>
                  <span className="visitaInfoDescricao">
                    Neste dia oramos juntos pelos pedidos da nossa comunidade e
                    também celebramos ao Senhor.
                  </span>
                  <Link
                    onClick={() => this.props.navegacaoContactos(3)}
                    to={{
                      pathname: `/contactos`,
                      state: { scroll: "visita" },
                    }}
                  >
                    <button className="visitarBtn">MARCAR VISITA</button>
                  </Link>
                </div>
                <div id="visitaInfoImagem2"></div>
              </div>
            </div>
          </div>
        </section>

        <div style={{ height: "80px" }} id="visitaPastoresEspaco"></div>
        <section id="pastores">
          <h1 className="titulo">NOSSOS PASTORES</h1>
          <div style={{ height: "8px" }}></div>
          <h5 className="subtitulo">Conheça os nosso líderes </h5>
          <div style={{ height: "26px" }}></div>

          <div id="pastoresLayout">
            <div id="pastor1">
              <div id="pastorFoto1"></div>
              <span className="pastorNome">Ana Tavares</span>
              <span className="pastorCargo">Pastora Fundadora</span>
              <div className="pastorContactos">
                <div
                  className="pastorContactosFb"
                  onClick={() =>
                    this.redeSocial("https://www.facebook.com/Ic.NascerdeNovo")
                  }
                ></div>
                <div
                  className="pastorContactosInsta"
                  onClick={() =>
                    this.redeSocial(
                      "https://www.instagram.com/anatavaresportugal"
                    )
                  }
                ></div>
                <div
                  className="pastorContactosWpp"
                  onClick={() =>
                    this.redeSocial(
                      "https://api.whatsapp.com/send?phone=351914515118"
                    )
                  }
                ></div>
              </div>
            </div>
            <div id="pastor2">
              <div id="pastorFoto2"></div>
              <span className="pastorNome">Rodrigo Rodrigues</span>
              <span className="pastorCargo">Pastor Assistente</span>
              <div className="pastorContactos">
                <div
                  className="pastorContactosFb"
                  onClick={() =>
                    this.redeSocial(
                      "https://www.facebook.com/rodrigo.t.rodrigues"
                    )
                  }
                ></div>
                <div
                  className="pastorContactosInsta"
                  onClick={() =>
                    this.redeSocial(
                      "https://www.instagram.com/rodrigo.t.rodrigues"
                    )
                  }
                ></div>
                <div
                  className="pastorContactosWpp"
                  onClick={() =>
                    this.redeSocial(
                      "https://api.whatsapp.com/send?phone=351933444263"
                    )
                  }
                ></div>
              </div>
            </div>
            <div id="pastor3">
              <div id="pastorFoto3"></div>
              <span className="pastorNome">Ana Rodrigues</span>
              <span className="pastorCargo">Pastora Assistente</span>
              <div className="pastorContactos">
                <div
                  className="pastorContactosFb"
                  onClick={() =>
                    this.redeSocial(
                      "https://www.facebook.com/anaemiliapereirarodrigues"
                    )
                  }
                ></div>
                <div
                  className="pastorContactosInsta"
                  onClick={() =>
                    this.redeSocial(
                      "https://www.instagram.com/anaemiliarodrigues"
                    )
                  }
                ></div>
                <div
                  className="pastorContactosWpp"
                  onClick={() =>
                    this.redeSocial(
                      "https://api.whatsapp.com/send?phone=351910029788"
                    )
                  }
                ></div>
              </div>
            </div>
          </div>
        </section>
        <div style={{ height: "150px" }}></div>
        <section id="redesSociais">
          <h1 className="titulo">CONHEÇA AS NOSSAS REDES SOCIAIS</h1>
          <div style={{ height: "26px" }}></div>
          <div id="redesSociaisLayout">
            <div
              className="redeSocial"
              onClick={() =>
                this.redeSocial(
                  "https://www.youtube.com/channel/UCZVA6G8XizmtMSF41c42b9Q"
                )
              }
            >
              <div id="redeSocial1"></div>
            </div>
            <div
              className="redeSocial"
              onClick={() =>
                this.redeSocial("https://www.instagram.com/i.nascerdenovo/")
              }
            >
              <div id="redeSocial2"></div>
            </div>
            <div
              className="redeSocial"
              onClick={() =>
                this.redeSocial("https://www.facebook.com/i.nascerdenovo")
              }
            >
              <div id="redeSocial3"></div>
            </div>
          </div>
        </section>
        <div style={{ height: "140px" }}></div>
        <section id="valores">
          <h1 className="titulo">OS NOSSOS VALORES</h1>
          <div style={{ height: "8px" }}></div>
          <h5 className="subtitulo">o que faz o nosso coração bater</h5>
          <div style={{ height: "36px" }}></div>
          <div className="valoresLayout">
            <div
              className="valor"
              onClick={() =>
                this.setState({
                  mostrarModal: !this.state.mostrarModal,
                  idModal: "valorModalLogo1",
                  tituloModal: "Jesus no Centro",
                  mensagemModal: [
                    "Acreditamos em uma mentalidade Cristocêntrica.",
                    "Tudo o que sabemos sobre Deus, sobre nós, as nossas esperanças, o universo, é mais bem entendido quando visto em um relacionamento com Jesus Cristo. Ele está no centro.",
                    "Ele é o Alfa e o Ômega, o princípio e o fim. Sem Jesus, nada faz sentido.",
                  ],
                  referenciasModal:
                    "Ver: João 14:6, João 11:25, 1 Timóteo 2:5, João 1:1, Hebreus 4:14-15, Colossenses 2:9, Gálatas 2:20, Marcos 16:6, Apocalipse 19:11-16",
                })
              }
            >
              <div className="valorFundo">
                <div id="valorLogo1"></div>
              </div>
              <span className="valorNome">Jesus</span>
              <span className="valorNome">no Centro</span>
            </div>
            <div
              className="valor"
              onClick={() =>
                this.setState({
                  mostrarModal: !this.state.mostrarModal,
                  idModal: "valorModalLogo2",
                  tituloModal: "Nascido de Novo",
                  mensagemModal: [
                    "Jesus disse que é preciso nascer de novo para entrar no Reino de Deus. Todos nós éramos considerados mortos em nossos pecados porque tínhamos essa inclinação natural.",
                    "Mas quando aceitamos Jesus como salvador recebemos vida nova!",
                    "\0",
                    "Nascer de novo é ter um vida livre do pecado, significa passar de orfãos para filhos de Deus.",
                    "Por consequência disso, a nossa vida e as nossas escolhas são entregues a Cristo em confiança.",
                    "Nós damos testemunho da nossa nova vida através do batismo.",
                    "Agora vivemos para Deus e Ele vive em nós.",
                  ],
                  referenciasModal:
                    "Ver: João 3:3, 2 Coríntios 5:17, João 3:5-7, 1 Pedro 1:3-4, João 1:12-13, 1 Pedro 1:23, Romanos 6:4, Gálatas 2:20, Romanos 6:11, Efésios 2:4-5, Colossenses 3:9-11, Efésios 4:22-24, Marcos 10:15, 1 Pedro 2:2-3",
                })
              }
            >
              <div className="valorFundo">
                <div id="valorLogo2"></div>
              </div>
              <span className="valorNome">Nascido</span>
              <span className="valorNome">de Novo</span>
            </div>
            <div
              className="valor"
              onClick={() =>
                this.setState({
                  mostrarModal: !this.state.mostrarModal,
                  idModal: "valorModalLogo3",
                  tituloModal: "Vida de Intimidade",
                  mensagemModal: [
                    "Acreditamos que devemos mostrar amor ao nosso Deus através do amor à pessoas deste mundo. O companheirismo e o discipulado transformam as pessoas. Não fomos criados para viver sozinhos. Fomos designados a uma vida de comunidade e partilha. A Igreja é luz do mundo e sal da terra onde quer que vá.",
                  ],
                  referenciasModal:
                    "Ver: Hebreus 10:24-25, Atos dos Apóstolos 2:42-47, Gálatas 6:2, Eclesiastes 4:9-12, 1 Tessalonicenses 5:14, Mateus 18:20, Filipenses 2:3-4, Colossenses 3:13, 1 Pedro 4:8-11",
                })
              }
            >
              <div className="valorFundo">
                <div id="valorLogo3"></div>
              </div>
              <span className="valorNome">Vida de</span>
              <span className="valorNome">intimidade</span>
            </div>
            <div
              className="valor"
              onClick={() =>
                this.setState({
                  mostrarModal: !this.state.mostrarModal,
                  idModal: "valorModalLogo4",
                  tituloModal: "Poder do Espírito Santo",
                  mensagemModal: [
                    "Jesus ascendeu aos céus após ter ressuscitado por nós, mas não nos deixou sozinhos. Ele entregou-nos a si próprio, através do Seu Espírito e prometeu que habitaria dentro de nós e que nunca nos deixaria.",
                    "\0",
                    "É o Espirito Santo que nos acompanha e nos auxilia.",
                    "Através do Seu poder, Jesus nos prometeu que faríamos coisas maiores do que Ele próprio fez aqui na Terra. Isso inclui milagres, cura, libertação de vidas, bem como o poder para vencer o pecado.",
                  ],
                  referenciasModal:
                    "Ver: Efésios 5:18, Atos dos Apóstolos 2:38, Lucas 4:18, João 14:16-26, Efésios 4:30, 2 Coríntios 1:22, Atos dos Apóstolos 2:1-5",
                })
              }
            >
              <div className="valorFundo">
                <div id="valorLogo4"></div>
              </div>
              <span className="valorNome">Poder do</span>
              <span className="valorNome">Espírito Santo</span>
            </div>
            <div
              className="valor"
              onClick={() =>
                this.setState({
                  mostrarModal: !this.state.mostrarModal,
                  idModal: "valorModalLogo5",
                  tituloModal: "Verdade das Escrituras",
                  mensagemModal: [
                    "A Bíblia como conhecemos hoje foi escrita por 40 autores, espalhados por diferentes continentes, durante um período de 1600 anos e, surpreendentemente apresenta uma única mensagem. Acreditamos que Deus deixou a Sua Palavra como o único manual de vida, capaz de balizar as nossas ações e estabelecer padrões de bondade, justiça, verdade e salvação.",
                  ],
                  referenciasModal:
                    "Ver: 2 Timóteo 3:15-17, Deuteronômio 6:5-9, 2 Timóteo 2:15, Marcos 12:24, Provérbios 3:1-2, Isaías 8:20, 1 Timóteo 4:13, Salmos 119:9-16, Romanos 15:4, Provérbios 4:13, Provérbios 6:23, Salmos 119:97-99, Salmos 119:105, 2 Pedro 3:17-18",
                })
              }
            >
              <div className="valorFundo">
                <div id="valorLogo5"></div>
              </div>
              <span className="valorNome">Verdade</span>
              <span className="valorNome">das Escrituras</span>
            </div>

            <div
              className="valor"
              onClick={() =>
                this.setState({
                  mostrarModal: !this.state.mostrarModal,
                  idModal: "valorModalLogo6",
                  tituloModal: "Intimidade",
                  mensagemModal: [
                    "Jesus não é uma idéia, não é um conceito abstrato, nem tampouco é uma representação. Jesus é uma pessoa! E Ele deseja intensamente que nós o possamos conhecer pessoalmente e intimamente.",
                    "\0",
                    "Deus Criou homem e a mulher no jardim com o propósito de ser seu amigo e pai presente (Genesis 3:8). Mesmo quando Adão e Eva decidiram quebrar esse relacionamento, Deus nunca deixou de mostrar-se próximo do ser humano. A derradeira vitória foi através de Jesus, Deus feito homem para habitar no meio de nós.",
                  ],
                  referenciasModal:
                    "Ver: Tiago 4:8, Jeremias 29:11, Salmos 42:1-2, Salmos 63:1-11, Isaías 65:24, João 17:22-23, Isaías 55:1-6, Sofonias 3:17, 1 João 1:9, Jeremias 33:3",
                })
              }
            >
              <div className="valorFundo">
                <div id="valorLogo6"></div>
              </div>
              <span className="valorNome">Intimidade</span>
            </div>
            <div
              className="valor"
              onClick={() =>
                this.setState({
                  mostrarModal: !this.state.mostrarModal,
                  idModal: "valorModalLogo7",
                  tituloModal: "Serviço",
                  mensagemModal: [
                    "Acreditamos que o serviço é o maior privilégio desta vida. Poder servir a Cristo e aos outros é ser imitador de Jesus, vivendo de forma humilde e desprovida de arrogância.",
                    "\0",
                    "Cristo nos revelou que o maior de nós também deve ser o maior servo (Mateus 23:11). E é por uma vida de serviço aos nossos irmãos, igreja, cidade, sociedade que queremos ser conhecidos.",
                  ],
                  referenciasModal:
                    "Ver: Mateus 23:11, João 12:26, Marcos 10:42-45, Lucas 22:27, Mateus 10:24, Romanos 12:10, Hebreus 6:10, Gálatas 5:13, 1 Pedro 4:10, Deuteronômio 13:4, Romanos 12:11, Romanos 12:7",
                })
              }
            >
              <div className="valorFundo">
                <div id="valorLogo7"></div>
              </div>
              <span className="valorNome">Serviço</span>
            </div>
            <div
              className="valor"
              onClick={() =>
                this.setState({
                  mostrarModal: !this.state.mostrarModal,
                  idModal: "valorModalLogo8",
                  tituloModal: "Excelência",
                  mensagemModal: [
                    "A mediocridade não faz parte do nosso vocabulário. Deus não poupou nenhum recurso para nos resgatar das amarras do pecado, portanto, nós devemos exceder o que é expectável no tocante ao nosso trabalho para a divulgação do Reino de Deus.",
                    "Somos chamados para a excelência, para um caminho mais excelente.",
                  ],
                  referenciasModal:
                    "Ver: Filipenses 4:8, Colossenses 3:23, Tito 2:7, João 4:23, Filipenses 1:9-10, 1 Coríntios 15:58",
                })
              }
            >
              <div className="valorFundo">
                <div id="valorLogo8"></div>
              </div>
              <span className="valorNome">Excelência</span>
            </div>
            <div
              className="valor"
              onClick={() =>
                this.setState({
                  mostrarModal: !this.state.mostrarModal,
                  idModal: "valorModalLogo9",
                  tituloModal: "Relevância",
                  mensagemModal: [
                    "A Igreja do Senhor deve estar na vanguarda dos avanços culturais, científicos, empresariais. O Reino de Deus veio para penetrar todas as camadas da sociedade e, assim, trazer transformação para a mesma.",
                    "O nosso desejo é que seja “na terra, como é no céu”.",
                  ],
                  referenciasModal: "Ver: Lucas 24: 17-19, Atos 2.42-47",
                })
              }
            >
              <div className="valorFundo">
                <div id="valorLogo9"></div>
              </div>
              <span className="valorNome">Relevância</span>
            </div>
            <div
              className="valor"
              onClick={() =>
                this.setState({
                  mostrarModal: !this.state.mostrarModal,
                  idModal: "valorModalLogo10",
                  tituloModal: "Honra",
                  mensagemModal: [
                    "Honrar significa respeitar profundamente o chamado, os dons e o próprio indivíduo, entendendo que ele é amado por Deus, mesmo tendo falhas de caráter e de conduta. A honra mantém a ordem natural de Deus intacta. Nós honramos as nossas autoridades, pais, chefes… Mas também os nossos subordinados, amigos, filhos, irmãos.",
                  ],
                  referenciasModal:
                    "Ver: Apocalipse 5:13, Provérbios 21:21, Romanos 12:10, Hebreus 13:18, Deuteronômio 5:16, 1 Timóteo 5:17-18, Deuteronômio 5:16",
                })
              }
            >
              <div className="valorFundo">
                <div id="valorLogo10"></div>
              </div>
              <span className="valorNome">Honra</span>
              <div
                className="modal"
                style={
                  this.state.mostrarModal
                    ? { display: "block" }
                    : { display: "none" }
                }
              >
                <div className="modal-content">
                  {/*
                  <span
                    className="close"
                    onClick={() => this.setState({ modal: 0 })}
                  >
                    &times;
                  </span>
                  */}
                  <div id={this.state.idModal}></div>
                  <span className="valorModalTitulo">
                    {this.state.tituloModal}
                  </span>
                  {this.state.mensagemModal.map((linha) => {
                    return <p className="valorModalDescricao">{linha}</p>;
                  })}

                  <div style={{ paddingTop: "30px" }} />
                  <p className="valorModalDescricao">
                    {this.state.referenciasModal}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div style={{ height: "80px" }}></div>
      </React.Fragment>
    );
  }
}

export default Home;
