import React from "react";
import { Link } from "react-router-dom";
import { formatarData } from "../DateHelper";

function MensagensDevocionaisItem({ devocional, visible }) {
  return (
    <Link
      to={{
        pathname: "/mensagem_devocional",
        state: {
          devocional: devocional,
        },
      }}
      className="mensagensDevocionaisFundo"
      style={{
        textDecoration: "none",
        color: "inherit",
        visibility: visible ? "visible" : "hidden",
      }}
    >
      <div
        className="mensagensDevocionaisFoto"
        style={{
          backgroundImage: "url('" + devocional.imagem + "')",
        }}
      >
        {/*  <img
          src={devocional.imagem}
          width="100%"
          alt=""
          style={{ objectFit: "contain" }}
        /> */}
      </div>
      <div className="mensagensDevocionaisConteudo">
        <span className="mensagensDevocionaisConteudoTitulo">
          {devocional.titulo}
        </span>
        <span className="mensagensDevocionaisConteudoDescricao">
          Por {devocional.autor} | {formatarData(devocional.data)}
        </span>
      </div>
    </Link>
  );
}

export default MensagensDevocionaisItem;
