import React from "react";

function Politica() {
  const boldstyle = {
    fontWeight: "bold",
    fontSize: "24px",
    marginTop: "36px",
    marginBottom: "4px",
  };
  const normalstyle = {
    fontSize: "16px",
    marginTop: "8px",
    color: "#7a7a84",
  };
  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        paddingLeft: "11%",
        paddingRight: "11%",
        flexDirection: "column",
        fontFamily: "Open Sans, sans-serif",
      }}
    >
      <span style={{ ...normalstyle, marginTop: "40px" }}>
        Por favor, leia atentamente este documento. As presentes condições estão
        em vigor para todos os Visitantes desde o dia 16 de Julho de 2021.
        Tentamos manter a nossa Política de Privacidade o mais clara possível, e
        dar-lhe todas as informações necessárias sobre a utilização dos dados em
        www.nascerdenovo.pt para que consiga compreender facilmente todo o
        processo, desde a sua recolha, processamento, utilização e eliminação. É
        muito importante para nós que a sua experiência corresponda às suas
        expectativas, por isso, agradecemos que utilize alguns minutos e
        continue a ler.
      </span>

      <span style={boldstyle}>RECOLHA E TRATAMENTO DE DADOS DO UTILIZADOR</span>
      <span style={normalstyle}>
        No âmbito da disponibilização do website alojado em www.nascerdenovo.pt
        e conteúdos ali disponibilizados, o nascerdenovo.pt com sede em Santa
        Maria da Feria, poderá requisitar ao Utilizador que disponibilize dados
        pessoais, isto é, informações fornecidas pelo Utilizador que permitam ao
        nascerdenovo.pt identificá-lo e/ou contatá-lo (“Dados Pessoais”).
      </span>
      <span style={normalstyle}>
        De forma geral, os Dados Pessoais são requisitados quando o Utilizador
        se regista no site ou quando preenche algum formulário presente na
        página dos contactos.
      </span>
      <span style={normalstyle}>
        Aquando da recolha dos Dados Pessoais, o nascerdenovo.pt presta ao
        Utilizador informações detalhadas acerca da natureza dos dados
        recolhidos e acerca da finalidade e do tratamento que será realizado
        relativamente aos Dados Pessoais.
      </span>
      <span style={normalstyle}>
        A Informação de Usabilidade e os Dados Pessoais são designados na
        presente Política de Privacidade por “Dados do Utilizador”.
      </span>
      <span style={boldstyle}>CANAIS DE RECOLHA DOS DADOS</span>
      <span style={normalstyle}>
        O nascerdenovo.pt apenas irá recolher dados de forma direta (i.e.,
        diretamente junto do Utilizador) através do website.
      </span>
      <span style={boldstyle}>
        PRINCÍPIOS GERAIS APLICÁVEIS AO TRATAMENTO DE DADOS DO UTILIZADOR
      </span>
      <span style={normalstyle}>
        Em termos de princípios gerais relativos ao tratamento de dados
        pessoais, o nascerdenovo.pt compromete-se a assegurar que os Dados do
        Utilizador por si tratados são:
      </span>
      <ul style={{ marginLeft: "50px", marginTop: "10px" }}>
        <li>
          <span style={normalstyle}>
            Objeto de um tratamento lícito, leal e transparente em relação ao
            Utilizador;
          </span>
        </li>
        <li>
          <span style={normalstyle}>
            Recolhidos para finalidades determinadas, explícitas e legítimas,
            não sendo tratados posteriormente de uma forma incompatível com
            essas finalidades;
          </span>
        </li>
        <li>
          <span style={normalstyle}>
            Adequados, pertinentes e limitados ao que é necessário relativamente
            às finalidades para as quais são tratados;
          </span>
        </li>
        <li>
          <span style={normalstyle}>
            Exatos e atualizados sempre que necessário, sendo adotadas todas as
            medidas adequadas para que os dados inexatos, tendo em conta as
            finalidades para que são tratados, sejam apagados ou retificados sem
            demora;
          </span>
        </li>
        <li>
          <span style={normalstyle}>
            Conservados de uma forma que permite a identificação do Utilizador
            apenas durante o período necessário para as finalidades para as
            quais os dados são tratados;
          </span>
        </li>
        <li>
          <span style={normalstyle}>
            Tratados de uma forma que garante a sua segurança, incluindo a
            proteção contra o seu tratamento não autorizado ou ilícito e contra
            a sua perda, destruição ou danificação acidental, sendo adotadas as
            medidas técnicas ou organizativas adequadas.
          </span>
        </li>
      </ul>
      <span style={normalstyle}>
        Os tratamentos de dados efetuados pelo nascerdenovo.pt são lícitos
        quando o Utilizador tiver dado o seu consentimento explícito para o
        tratamento dos Dados do Utilizador para uma ou mais finalidades
        específicas.
      </span>
      <span style={normalstyle}>
        O nascerdenovo.pt compromete-se a assegurar que o tratamento dos Dados
        do Utilizador apenas é feito nas condições acima elencadas e com
        respeito pelos princípios acima mencionados.
      </span>
      <span style={normalstyle}>
        Quando o tratamento dos Dados do Utilizador for realizado pelo
        nascerdenovo.pt com base no consentimento do Utilizador, este tem o
        direito de retirar o seu consentimento a qualquer momento. A retirada do
        consentimento, todavia, não compromete a licitude do tratamento efetuado
        pelo nascerdenovo.pt com base no consentimento previamente dado pelo
        Utilizador.
      </span>
      <span style={normalstyle}>
        O período de tempo durante o qual os dados são armazenados e conservados
        varia de acordo com a finalidade para a qual a informação é tratada.
      </span>
      <span style={normalstyle}>
        O dados serão armazenados e conservados apenas pelo período mínimo
        necessário para as finalidades que motivaram a sua recolha ou o seu
        posterior tratamento, findo o qual os mesmos serão eliminados.
      </span>
      <span style={normalstyle}>
        Apenas são armazenados dados diretamente disponibilizados pelo
        utilizador.
      </span>
      <span style={boldstyle}>
        UTILIZAÇÃO E FINALIDADES DO TRATAMENTO DE DADOS DO UTILIZADOR
      </span>
      <span style={normalstyle}>
        O nascerdenovo.pt utiliza os Dados do Utilizador para as seguintes
        finalidades:
      </span>
      <ul style={{ marginLeft: "50px", marginTop: "10px" }}>
        <li>
          <span style={normalstyle}>Registo do Utilizador no Site;</span>
        </li>
        <li>
          <span style={normalstyle}>
            Permitir o acesso a áreas restritas do Site, de acordo com termos
            previamente estabelecidos;
          </span>
        </li>
        <li>
          <span style={normalstyle}>Marcações de visita;</span>
        </li>
        <li>
          <span style={normalstyle}>Envio de pedidos de oração.</span>
        </li>
      </ul>
      <span style={normalstyle}>
        Os Dados do Utilizador recolhidos pelo nascerdenovo.pt não são
        partilhados com terceiros e apenas são mantidos durante o tempo
        necessário para a sua finalidade.
      </span>
      <span style={boldstyle}>QUE DADOS SÃO ARMAZENADOS?</span>
      <span style={normalstyle}>Dados armazenados na marcação de visita:</span>
      <ul style={{ marginLeft: "50px" }}>
        <li>
          <span style={normalstyle}>Nome;</span>
        </li>
        <li>
          <span style={normalstyle}>Contacto (opcional);</span>
        </li>
        <li>
          <span style={normalstyle}>Informação adicional (opcional);</span>
        </li>
        <li>
          <span style={normalstyle}>Se o utilizador leva crianças;</span>
        </li>
        <li>
          <span style={normalstyle}>
            Se o utilizador pretende ser contactado;
          </span>
        </li>
      </ul>
      <span style={normalstyle}>
        Dados armazenados no envio de um pedido de oração:
      </span>
      <ul style={{ marginLeft: "50px" }}>
        <li>
          <span style={normalstyle}>Nome;</span>
        </li>
        <li>
          <span style={normalstyle}>Contacto (opcional);</span>
        </li>
        <li>
          <span style={normalstyle}>Pedido de oração;</span>
        </li>
        <li>
          <span style={normalstyle}>
            Se o utilizador pretende ser contactado;
          </span>
        </li>
      </ul>
      <span style={normalstyle}>Dados armazenados no registo:</span>
      <ul style={{ marginLeft: "50px" }}>
        <li>
          <span style={normalstyle}>Nome;</span>
        </li>
        <li>
          <span style={normalstyle}>Email;</span>
        </li>
        <li>
          <span style={normalstyle}>Palavra-passe (encriptada);</span>
        </li>
      </ul>
      <span style={boldstyle}>
        MEDIDAS TÉCNICAS, ORGANIZATIVAS E DE SEGURANÇA IMPLEMENTADAS
      </span>
      <span style={normalstyle}>
        Para garantir a segurança dos Dados do Utilizador e a máxima
        confidencialidade, tratamos a informação que nos forneceu de forma
        absolutamente confidencial, de acordo com as nossas políticas e
        procedimentos internos de segurança e confidencialidade, os quais são
        atualizados periodicamente consoante as necessidades, bem como de acordo
        com os termos e condições legalmente previstos.
      </span>
      <span style={normalstyle}>
        Em função da natureza, do âmbito, do contexto e das finalidades do
        tratamento dos dados, bem como dos riscos decorrentes do tratamento para
        os direitos e liberdades do Utilizador, o nascerdenovo.pt compromete-se
        a aplicar, tanto no momento de definição dos meios de tratamento como no
        momento do próprio tratamento, as medidas técnicas e organizativas
        necessárias e adequadas à proteção dos Dados do Utilizador e ao
        cumprimento dos requisitos legais.
      </span>
      <span style={boldstyle}>
        TRANSFERÊNCIA DE DADOS PARA FORA DO UNIÃO EUROPEIA
      </span>
      <span style={normalstyle}>
        Os dados pessoais recolhidos e utilizados pelo nascerdenovo.pt não são
        disponibilizados a terceiros estabelecidos fora da União Europeia.
      </span>
      <span style={boldstyle}>UTILIZAÇÃO DE COOKIES</span>
      <span style={normalstyle}>
        Os cookies são pequenos ficheiros de informação que são enviados para o
        seu computador ou telemóvel quando visita um website. Os cookies são
        enviados de volta ao site de origem em cada visita subsequente ou para
        outro site que reconheça esse cookie. Os cookies são úteis porque
        permitem que um site reconheça o dispositivo do utilizador,
        permitindo-lhe navegar eficientemente pelas páginas, recordando também
        as suas preferências e melhorando em geral a experiência do utilizador.
      </span>
      <span style={normalstyle}>
        Os cookies utilizados em nascerdenovo.pt são apenas usados para guardar
        a sessão atual do utilizador e expirarão depois de 10 horas.
      </span>
      <span style={normalstyle}>
        A maioria dos browsers aceitam cookies automaticamente. Pode modificar a
        configuração do seu browser para não aceitar ou para ser notificado cada
        vez que se cria um cookie. Pode obter mais informação sobre cookies,
        incluindo como ver os cookies que foram criados no seu dispositivo e
        como geri-los ou eliminá-los usando diferentes tipos de browsers, em
        www.allaboutcookies.org.
      </span>
      <span style={normalstyle}>
        A maioria dos browsers aceitam cookies automaticamente. Pode modificar a
        configuração do seu browser para não aceitar ou para ser notificado cada
        vez que se cria um cookie. Pode obter mais informação sobre cookies,
        incluindo como ver os cookies que foram criados no seu dispositivo e
        como geri-los ou eliminá-los usando diferentes tipos de browsers, em
        https://www.freeprivacypolicy.com/blog/cookies/.
      </span>
      <span style={normalstyle}>
        A capacidade de ativar, desativar ou eliminar cookies também se pode
        efetuar no seu browser. Para isso, siga as instruções no seu browser
        (geralmente localizadas nas opções “Ajuda”, “Ferramentas” ou “Editar”).
        Desativar um cookie ou uma categoria de cookies não apaga o cookie do
        seu browser, para tal necessita efetuar o apagamento manualmente.
      </span>
      <span style={normalstyle}>
        Ao bloquear ou eliminar cookies usados pelo nascerdenovo.pt, é possível
        que não consiga disfrutar ao máximo das funcionalidades do website.
      </span>
      <div style={{ marginTop: "150px" }}></div>
    </div>
  );
}

export default Politica;
