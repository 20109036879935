import React, { useState, createContext } from "react";

export const SessionContext = createContext();

export const SessionProvider = (props) => {
  const [session, setSession] = useState({
    nome: "",
    email: "",
    cargo: "",
    token: "",
    logado: false,
  });

  return (
    <SessionContext.Provider value={[session, setSession]}>
      {props.children}
    </SessionContext.Provider>
  );
};
