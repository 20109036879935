import React from "react";

export default function ColunaNecessidadePlaneamento({
  colunaPlaneamento,
  colunaPlaneamentoKey,
  incrementarDecrementarColunaPlaneamento,
}) {
  return (
    <div className="planeamentoCultosColuna">
      <span className="planeamentoCultosColunaTitulo">
        {colunaPlaneamento.nome}
      </span>
      {colunaPlaneamento.necessidades.map((necessidade, index) => {
        return (
          <div key={index} className="planeamentoCultosColunaNecessidade">
            <span className="planeamentoCultosColunaNecessidadeTitulo">
              {necessidade.descricao}
            </span>
            <div className="planeamentoCultosColunaNecessidadeQuantidade">
              <div className="planeamentoCultosColunaNecessidadeQuantidadeMostrar">
                <div className="planeamentoCultosColunaNecessidadeQuantidadeMostrarNumero">
                  {necessidade.quantidade}
                </div>
                <span className="planeamentoCultosColunaNecessidadeQuantidadeMostrarTexto">
                  Precisos
                </span>
              </div>
              <div className="planeamentoCultosColunaNecessidadeQuantidadeBotoes">
                <div
                  className="planeamentoCultosColunaNecessidadeQuantidadeBotoesBotao"
                  style={{ fontSize: "35px", paddingBottom: "2.5px" }}
                  onClick={() =>
                    incrementarDecrementarColunaPlaneamento(
                      "-",
                      colunaPlaneamentoKey,
                      index
                    )
                  }
                >
                  -
                </div>
                <span className="planeamentoCultosColunaNecessidadeQuantidadeBotoesQuantidade">
                  {necessidade.quantidade}
                </span>
                <div
                  className="planeamentoCultosColunaNecessidadeQuantidadeBotoesBotao"
                  onClick={() =>
                    incrementarDecrementarColunaPlaneamento(
                      "+",
                      colunaPlaneamentoKey,
                      index
                    )
                  }
                >
                  +
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}
