import React, { useState, useContext, useRef } from "react";
import { SessionContext } from "../SesstionContext";
import { Redirect, Link } from "react-router-dom";
import { getTokenCookie } from "../CookieHelper";
import axios from "axios";
import "../css/registo.css";

export default function Registo() {
  const nomeRef = useRef();
  const [erroNome, setErroNome] = useState("");
  const emailRef = useRef();
  const [erroEmail, setErroEmail] = useState("");
  const passwordRef = useRef();
  const [erroPassword, setErroPassword] = useState("");
  const [session, setSession] = useContext(SessionContext);

  const fazerRegisto = (e) => {
    e.preventDefault();

    setErroNome("");
    setErroEmail("");
    setErroPassword("");

    const nome = nomeRef.current.value.trim();
    const email = emailRef.current.value.trim();
    const password = passwordRef.current.value;

    if (nome.length === 0) {
      setErroNome("Por favor insira o seu nome");
      return;
    } else if (email.length === 0) {
      setErroEmail("Por favor insira o seu email");
      return;
    } else if (!email.includes("@") || !email.includes(".")) {
      setErroEmail("Email com formato inválido");
      return;
    } else if (password.length === 0) {
      setErroPassword("Por favor insira a sua palavra-passe");
      return;
    } else if (password.length < 6) {
      setErroPassword(
        "A palavra-passe necessita de ter pelo menos 6 caracteres"
      );
      return;
    }

    const registoBotao = document.getElementById("registoBtn");
    registoBotao.classList.add("noHover");

    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/api/v1/register`,
        {
          name: nome,
          email: email,
          password: password,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      )
      .then(
        (response) => {
          const { jwt, name, email, userRole } = response.data;
          setSession({
            nome: name,
            email: email,
            cargo: userRole,
            token: jwt,
            logado: true,
          });
          document.cookie =
            "token=" + jwt + ";expires=" + new Date(2100, 0, 1).toUTCString();
          registoBotao.classList.remove("noHover");
        },
        (error) => {
          if (
            error.response !== undefined &&
            error.response.data.statusCode === "2"
          ) {
            setErroEmail("Email já registado");
          } else {
            setErroEmail("Ocorreu um erro");
          }
          registoBotao.classList.remove("noHover");
        }
      );
  };

  const verificarToken = () => {
    const token = getTokenCookie("token");

    if (token.length > 0 && session.email === "") {
      return <Redirect to="/redirect" />;
    }
  };

  return (
    <div id="registoLayout">
      <section id="registo">
        <div id="registoFundo">
          <span id="registoTitulo">Criar Nova Conta</span>
          <div style={{ marginTop: "50px" }} />

          <form onSubmit={fazerRegisto}>
            <input
              type="text"
              id="loginNome"
              placeholder="Nome"
              className="campoFormulario"
              name="nome"
              ref={nomeRef}
              maxLength="100"
            />
            <span
              className="registoErro"
              style={erroNome ? { display: "block" } : { display: "none" }}
            >
              {erroNome}
            </span>
            <input
              type="text"
              id="loginEmail"
              placeholder="Email"
              className="campoFormulario"
              name="email"
              ref={emailRef}
              maxLength="100"
            />
            <span
              className="registoErro"
              style={erroEmail ? { display: "block" } : { display: "none" }}
            >
              {erroEmail}
            </span>
            <input
              type="password"
              id="loginPassword"
              placeholder="Palavra-Passe"
              className="campoFormulario"
              name="password"
              ref={passwordRef}
              maxLength="100"
            />
            <span
              className="registoErro"
              style={erroPassword ? { dosplay: "block" } : { display: "none" }}
            >
              {erroPassword}
            </span>
            <button id="registoBtn">CRIAR CONTA</button>
          </form>

          <span id="registoContaExistente">
            <Link
              to="/login"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              Já possui uma conta?
            </Link>
          </span>
          <div className="textPoliticaPrivacidadeRegisto">
            Ao submeter o formulário está a aceitar os nossos termos e
            condições. Consulte
            <Link
              to="/politica"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <span style={{ fontWeight: "500", cursor: "pointer" }}>
                &nbsp;aqui&nbsp;
              </span>
            </Link>
            a nossa Política de Dados.
          </div>
        </div>
      </section>
      <div id="registoFoto"></div>
      {session.logado ? <Redirect to="/redirect" /> : null}
      {verificarToken()}
    </div>
  );
}
