import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "../css/contactos.css";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { gerarData } from "../DateHelper";

export default function Contactos(props) {
  const [nomeMarcarVisita, setNomeMarcarVisita] = useState("");
  const [contactoMarcarVisita, setContactoMarcarVisita] = useState("");
  const [informacaoMarcarVisita, setInformacaoMarcarVisita] = useState("");
  const [criancasMarcarVisita, setCriancasMarcarVisita] = useState(false);
  const [contactarMarcarVisita, setContactarMarcarVisita] = useState(false);
  const [nomeFaltaMarcarVisita, setNomeFaltaMarcarVisita] = useState(false);
  const [nomePedidoOracao, setNomePedidoOracao] = useState("");
  const [contactoPedidoOracao, setContactoPedidoOracao] = useState("");
  const [pedidoPedidoOracao, setPedidoPedidoOracao] = useState("");
  const [contactarPedidoOracao, setContactarPedidoOracao] = useState(false);
  const [nomeFaltaPedidoOracao, setNomeFaltaPedidoOracao] = useState(false);
  const [pedidoFaltaPedidoOracao, setpedidoFaltaPedidoOracao] = useState(false);
  const marcarVisitaRef = useRef();
  const pedidoOracaoRef = useRef();

  useEffect(() => {
    if (
      typeof props.location.state !== "undefined" &&
      typeof props.location.state.scroll !== "undefined"
    )
      if (props.location.state.scroll === "visita") {
        scrollToMyRef(marcarVisitaRef);
      }

    // eslint-disable-next-line
  }, []);

  const scrollToMyRef = (ref) => window.scrollTo(0, ref.current.offsetTop);

  const marcarVisita = (e) => {
    e.preventDefault();

    setNomeFaltaMarcarVisita(false);

    if (nomeMarcarVisita.length === 0) {
      setNomeFaltaMarcarVisita(true);
      return;
    }

    const contactanosBtn = document.getElementById("marcarVisitaBtn");
    contactanosBtn.classList.add("noHover");

    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/api/v1/visitas`,
        {
          nome: nomeMarcarVisita,
          contacto: contactoMarcarVisita,
          informacaoAdicional: informacaoMarcarVisita,
          levaCriancas: criancasMarcarVisita,
          entrarEmContacto: contactarMarcarVisita,
          data: gerarData(),
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      )
      .then(
        (response) => {
          setNomeMarcarVisita("");
          setContactoMarcarVisita("");
          setInformacaoMarcarVisita("");
          setCriancasMarcarVisita(false);
          setContactarMarcarVisita(false);
          toast.success("✔ Visita marcada com sucesso!", {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
          });
          contactanosBtn.classList.remove("noHover");
        },
        (error) => {
          toast.error("❌ Ocorreu um erro ao marcar a visita", {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
          });
          contactanosBtn.classList.remove("noHover");
        }
      );
  };

  const pedidoOracao = (e) => {
    e.preventDefault();

    setNomeFaltaPedidoOracao(false);
    setpedidoFaltaPedidoOracao(false);

    if (nomePedidoOracao.length === 0) {
      setNomeFaltaPedidoOracao(true);
      return;
    } else if (pedidoPedidoOracao.length === 0) {
      setpedidoFaltaPedidoOracao(true);
      return;
    }

    const pedidoOracaoBtn = document.getElementById("pedidoOracaoBtn");
    pedidoOracaoBtn.classList.add("noHover");

    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/api/v1/pedidosOracao`,
        {
          nome: nomePedidoOracao,
          contacto: contactoPedidoOracao,
          pedido: pedidoPedidoOracao,
          entrarEmContacto: contactarPedidoOracao,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      )
      .then(
        (response) => {
          setNomePedidoOracao("");
          setContactoPedidoOracao("");
          setPedidoPedidoOracao("");
          setContactarPedidoOracao(false);
          toast.success("✔ Pedido de oração enviado com sucesso!", {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
          });
          pedidoOracaoBtn.classList.remove("noHover");
        },
        (error) => {
          toast.error("❌ Ocorreu um erro ao enviar o pedido de oração", {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
          });
          pedidoOracaoBtn.classList.remove("noHover");
        }
      );
  };

  return (
    <div id="fundo">
      <ToastContainer
        position="bottom-center"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover={false}
      />

      {/*
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <iframe
          title="Inscrição "
          src="https://docs.google.com/forms/d/e/1FAIpQLSeeosGa6jsM5B7mMAHzANPKA5rsd4mSvEQwlXnu50caczSs5g/viewform?embedded=true"
          width="640"
          height="1144"
          frameborder="0"
          marginheight="0"
          marginwidth="0"
        >
          A carregar formulário…
        </iframe>
      </div>
*/}
      <div style={{ height: "80px" }}></div>

      <section id="contactos">
        <h1 ref={marcarVisitaRef} className="titulo">
          MARCAR VISITA
        </h1>
        <div style={{ height: "86px" }} id="separadorTituloMarcarVisita"></div>

        <div id="marcarVisitaLayout">
          <div id="marcarVisitaFormulario">
            <form onSubmit={marcarVisita}>
              <div className="marcarVisitaFormularioLinha">
                <input
                  type="text"
                  id="nomeMarcarVisita"
                  placeholder="O seu nome"
                  className="campoFormulario"
                  name="nomeMarcarVisita"
                  value={nomeMarcarVisita}
                  onChange={(e) => setNomeMarcarVisita(e.target.value)}
                  maxLength="100"
                ></input>
                <input
                  type="text"
                  id="contactoMarcarVisita"
                  placeholder="O seu contacto (opcional)"
                  className="campoFormulario"
                  name="nomeMarcarVisita"
                  value={contactoMarcarVisita}
                  onChange={(e) => setContactoMarcarVisita(e.target.value)}
                  maxLength="14"
                ></input>
              </div>

              <textarea
                placeholder="Informação adicional (opcional)"
                className="campoFormulario"
                style={{ resize: "none" }}
                rows="3"
                name="informacaoMarcarVisita"
                value={informacaoMarcarVisita}
                onChange={(e) => setInformacaoMarcarVisita(e.target.value)}
                maxLength="400"
              />
              <div className="marcarVisitaFormularioLinha">
                <label className="container">
                  Levo crianças
                  <input
                    type="checkbox"
                    name="criancasMarcarVisita"
                    checked={criancasMarcarVisita}
                    onChange={() =>
                      setCriancasMarcarVisita(!criancasMarcarVisita)
                    }
                  />
                  <span className="checkmark"></span>
                </label>
              </div>
              <div style={{ paddingTop: "12px" }} />
              <div className="marcarVisitaFormularioLinha">
                <label className="container">
                  Quero que entrem em contacto comigo
                  <input
                    type="checkbox"
                    name="contactarMarcarVisita"
                    checked={contactarMarcarVisita}
                    onChange={() =>
                      setContactarMarcarVisita(!contactarMarcarVisita)
                    }
                  />
                  <span className="checkmark"></span>
                </label>
              </div>
              <button id="marcarVisitaBtn" className="btnFormulario">
                CONTACTA-NOS
              </button>
              <span
                className="contactosCampoEmFalta"
                style={
                  nomeFaltaMarcarVisita
                    ? { display: "block" }
                    : { display: "none" }
                }
              >
                Por favor insira o seu nome
              </span>
              <div className="textPoliticaPrivacidadeMarcarVisita">
                Ao submeter o formulário está a aceitar os nossos termos e
                condições. Consulte
                <Link
                  to="/politica"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  <span style={{ fontWeight: "500", cursor: "pointer" }}>
                    &nbsp;aqui&nbsp;
                  </span>
                </Link>
                a nossa Política de Dados.
              </div>
            </form>
          </div>

          <div id="marcarVisitaMorada">
            <span className="marcarVisitaMoradaTitulo">Morada:</span>
            <span className="marcarVisitaMoradaDescricao">
              Rua principal do Bolhão
            </span>

            <span className="marcarVisitaMoradaDescricao">
              238 Loja 20 4505-374 Fiães
            </span>
            <span className="marcarVisitaMoradaTitulo">Email:</span>
            <span className="marcarVisitaMoradaDescricao">
              i.nascerdenovo@gmail.com
            </span>
            <span className="marcarVisitaMoradaTitulo">Contactos:</span>
            <span className="marcarVisitaMoradaDescricao">
              Ana Tavares: 914 515 118
            </span>
            <span className="marcarVisitaMoradaDescricao">
              Rodrigo Rodrigues: 933 444 263
            </span>
            <span className="marcarVisitaMoradaDescricao">
              Ana Emília Rodrigues: 910 029 788
            </span>
            <div style={{ paddingTop: "20px" }} />
          </div>
        </div>

        <div style={{ height: "140px" }}></div>
        <h1 ref={pedidoOracaoRef} className="titulo">
          ENVIAR PEDIDO DE ORAÇÃO
        </h1>

        <div style={{ height: "86px" }}></div>

        <div id="pedidoOracaoLayout">
          <div id="pedidoOracaoFundo">
            <div id="pedidoOracaoIlustracao">
              <div id="pedidoOracaoImagem" />
              <span id="pedidoOracaoVersiculo">
                Portanto, confessem os seus pecados uns aos outros e orem uns
                pelos outros para serem curados. A oração de um justo é poderosa
                e eficaz.
              </span>
              <span id="pedidoOracaoReferencia">Tiago 5:16</span>
            </div>
            <div id="pedidoOracaoFormulario">
              <form onSubmit={pedidoOracao}>
                <div className="PedidoOracaoFormularioLinha">
                  <input
                    type="text"
                    id="nomePedidoOracao"
                    placeholder="O seu nome"
                    className="campoFormulario"
                    name="nomePedidoOracao"
                    value={nomePedidoOracao}
                    onChange={(e) => setNomePedidoOracao(e.target.value)}
                    maxLength="100"
                  ></input>
                  <input
                    type="text"
                    id="contactoPedidoOracao"
                    placeholder="O seu contacto (opcional)"
                    className="campoFormulario"
                    name="contactoPedidoOracao"
                    value={contactoPedidoOracao}
                    onChange={(e) => setContactoPedidoOracao(e.target.value)}
                    maxLength="14"
                  ></input>
                </div>
                <textarea
                  placeholder="Escreva aqui o seu pedido"
                  className="campoFormulario"
                  style={{ resize: "none" }}
                  rows="3"
                  name="pedidoPedidoOracao"
                  value={pedidoPedidoOracao}
                  onChange={(e) => setPedidoPedidoOracao(e.target.value)}
                  maxLength="400"
                />
                <div className="marcarVisitaFormularioLinha">
                  <label className="container">
                    Quero que entrem em contacto comigo
                    <input
                      type="checkbox"
                      name="contactarPedidoOracao"
                      checked={contactarPedidoOracao}
                      onChange={() =>
                        setContactarPedidoOracao(!contactarPedidoOracao)
                      }
                    />
                    <span className="checkmark"></span>
                  </label>
                </div>
                <button id="pedidoOracaoBtn" className="btnFormulario">
                  ENVIAR PEDIDO
                </button>
                <span
                  className="contactosCampoEmFalta"
                  style={
                    nomeFaltaPedidoOracao
                      ? { display: "block" }
                      : { display: "none" }
                  }
                >
                  Por favor insira o seu nome
                </span>
                <span
                  className="contactosCampoEmFalta"
                  style={
                    pedidoFaltaPedidoOracao
                      ? { display: "block" }
                      : { display: "none" }
                  }
                >
                  Por favor insira o seu pedido
                </span>
                <div className="textPoliticaPrivacidadePedidoOracao">
                  Ao submeter o formulário está a aceitar os nossos termos e
                  condições. Consulte
                  <Link
                    to="/politica"
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    <span style={{ fontWeight: "500", cursor: "pointer" }}>
                      &nbsp;aqui&nbsp;
                    </span>
                  </Link>
                  a nossa Política de Dados.
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      <div style={{ height: "80px" }}></div>
    </div>
  );
}
